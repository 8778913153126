import { css } from '@emotion/css';
import { Entity } from 'cesium';

import { useEffect, useRef } from 'react';

import { Route } from '../../api/types';
import { filterAircraft } from '../../state/aircraft';
import useStore from '../../state/state';
import { LatLng, MapUndoAction } from '../../types/types';
import { getEntityType } from './cesium/entities';
import initCesium from './cesium/init';
import { EntityType } from './types';

type MapProps = {
  initialPosition: LatLng;
  setSetCorridor: (f: { set: (corridorWidthMeters: number) => void }) => void;
  setDoUndo: (f: { do: (a: MapUndoAction) => void }) => void;
  setUndoActions: (a: MapUndoAction[]) => void;
  setFlyTo: (f: { do: (p: LatLng) => void }) => void;
  setSetRoute: (f: { set: (r: Route) => void }) => void;
  setSetLocked: (f: { set: (l: boolean) => void }) => void;
  setSetHeading: (f: { set: (h: number) => void }) => void;
};

export default function Map({
  initialPosition,
  setSetCorridor,
  setDoUndo,
  setUndoActions,
  setFlyTo,
  setSetRoute,
  setSetLocked,
  setSetHeading,
}: MapProps) {
  const cesiumRef = useRef<HTMLDivElement>(null);

  const setModalStation = useStore((store) => store.setModalStation);
  const mapSettings = useStore((store) => store.mapSettings);
  const setHeading = useStore((store) => store.setHeading);
  const routeState = useStore((store) => store.routeState);
  const setRouteState = useStore((store) => store.setRouteState);
  const measureDistanceState = useStore((store) => store.measureDistanceState);
  const setMeasureDistanceState = useStore(
    (store) => store.setMeasureDistanceState
  );
  const lastUserCoordinates = useStore((store) => store.lastUserCoordinates);
  const setLastUserCoordinates = useStore(
    (store) => store.setLastUserCoordinates
  );

  if (mapSettings.rememberUserLocation && lastUserCoordinates) {
    initialPosition = {
      latitude: lastUserCoordinates.latitude,
      longitude: lastUserCoordinates.longitude,
    };
  }

  useEffect(() => {
    if (!cesiumRef.current) {
      return;
    }
    const container = cesiumRef.current;

    const onEntitySelected = (entity?: Entity) => {
      if (!entity) {
        return;
      }
      const entityType = getEntityType(entity);
      if (
        entityType === EntityType.MWOS ||
        entityType === EntityType.CameraSite ||
        entityType === EntityType.WeatherStation
      ) {
        const details = entity.properties?.stationDetails.valueOf();
        if (!details.id || !details.stationType) {
          console.error('entity is missing id or stationType properties');
          return;
        }
        setModalStation({
          id: details.id,
          type: details.stationType,
        });
      }
    };

    // const stats = new Stats();
    // stats.showPanel(0);
    // document.body.appendChild(stats.dom);
    // stats.dom.className = 'stats';

    const cesium = initCesium({
      container,
      initialPosition,
      onEntitySelected,
      setRouteState,
      setUndoActions,
      mapSettings,
      setHeading,
      routeState,
      measureDistanceState,
      setMeasureDistanceState,
      setLastUserCoordinates,
      useGoogleTiles: window.location.href.match(/google=true/g) ? true : false,
    });

    setSetCorridor({ set: cesium.setCorridor });
    setDoUndo({ do: cesium.undo });
    setFlyTo({ do: cesium.flyTo });
    setSetRoute({ set: cesium.setRoute });
    setSetLocked({ set: cesium.setLocked });
    setSetHeading({ set: cesium.setHeading });

    // const unsubUserLocation = useStore.subscribe(
    //   (state) => state.userLocation,
    //   (userLocation, prev) => {
    //     console.log({ userLocation });
    //     if (prev === undefined && userLocation !== undefined) {
    //       cesium.flyTo(userLocation, undefined, 2e5);
    //     }
    //   }
    // );
    const unsubModalStation = useStore.subscribe(
      (state) => state.modalStation,
      (modalStation) => {
        if (cesium.viewer && !modalStation) {
          cesium.viewer.selectedEntity = undefined;
        }
      }
    );
    const unsubAircraft = useStore.subscribe(
      (state) => state.aircraft,
      (aircraft) => {
        const filtered = filterAircraft(
          aircraft,
          useStore.getState().aircraftFilters
        );
        cesium.setAircraft(filtered);
      }
    );
    const unsubAircraftFilters = useStore.subscribe(
      (state) => state.aircraftFilters,
      (filters) => {
        const filtered = filterAircraft(useStore.getState().aircraft, filters);
        cesium.setAircraft(filtered);
      }
    );
    const unsubMapSettings = useStore.subscribe(
      (state) => state.mapSettings,
      (mapSettings) => {
        cesium.setMapSettings(mapSettings);
      }
    );
    const unsubRouteState = useStore.subscribe(
      (state) => state.routeState,
      (routeState) => {
        cesium.setRouteState(routeState);
      }
    );
    const unsubMeasureDistanceState = useStore.subscribe(
      (state) => state.measureDistanceState,
      (measureDistanceState) =>
        cesium.setMeasureDistanceState(measureDistanceState)
    );

    return () => {
      // unsubUserLocation();
      unsubModalStation();
      unsubAircraft();
      unsubAircraftFilters();
      unsubMeasureDistanceState();
      unsubMapSettings();
      unsubRouteState();
      cesium.dispose();
    };
    // Ignore exhaustive deps rule here because we NEVER want a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="cesium-root" className={styles} ref={cesiumRef} />;
}

const styles = css({
  position: 'absolute',
  height: '100svh',
  width: '100svw',
  overflow: 'hidden',
});
