import {
  ArcType,
  Cartesian3,
  Color,
  Entity,
  PolylineDashMaterialProperty,
} from 'cesium';

type Props = {
  points: [number, number, number][];
};

export default function createAircraftTrackEntity({ points }: Props): Entity {
  return new Entity({
    polyline: {
      positions: Cartesian3.fromDegreesArrayHeights(points.flat()),
      zIndex: 0,
      width: 2,
      arcType: ArcType.GEODESIC,
      material: new PolylineDashMaterialProperty({
        color: Color.fromCssColorString('#ff000055'),
        gapColor: Color.TRANSPARENT,
        dashLength: 5,
      }),
    },
  });
}
