import { css } from '@emotion/css';
import { milesToKm } from '../../../utils/map';
import IconClose from '../../svg-icons/Close';

type Props = {
  totalDistance: number;
  onClose: () => void;
};

export default function MeasureDistanceInfo({ totalDistance, onClose }: Props) {
  return (
    <div className={style}>
      <button className="close-button" title="X" onClick={onClose}>
        <IconClose width={30} height={30} />
      </button>
      <div className="title">Measure Distance</div>
      <div className="instructions">Click on the map to add to your path</div>
      <div className="distance">
        Total distance: {totalDistance.toFixed(2)} mi (
        {milesToKm(totalDistance).toFixed(2)} km)
      </div>
    </div>
  );
}

const width = 300;

const style = css({
  backgroundColor: '#ffffffdd',
  position: 'absolute',
  width,
  height: 120,
  bottom: 10,
  left: `calc(50% - ${width / 2}px)`,
  padding: '15px 15px',
  borderRadius: 5,

  '.close-button': {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 0,
    right: 0,
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },

  '.title': {
    fontWeight: 500,
    marginBottom: 5,
  },

  '.instructions': {
    fontSize: 14,
    opacity: 0.85,
    marginBottom: 10,
  },

  '.distance': {
    fontSize: 15,
  },
});
