/**
 * Below are the colors that are used in the app. The colors are defined in the light and dark mode.
 */

const tintColorLight = '#2f65bf';
const tintColorDark = '#fff';

const montisBlue = '#1b456d';

export const colors = {
  // Universal colors
  primary: montisBlue,
  primaryLight: '#2f65bf',
  primaryLighter: '#77afff',
  success: '#00dd33',
  danger: '#ED2020CD',
  warning: '#f4902e',
  vfr: '#3d993d',
  ifr: '#cc2727',
  mvfr: '#4a7ad9',
  lifr: '#cc27cc',

  // Light mode
  light: {
    text: '#444',
    background: '#fff',
    tint: tintColorLight,
    icon: '#687076',
    tabIconDefault: '#687076',
    tabIconSelected: tintColorLight,
    map: {
      controlBackground: '#ffffffdd',
      controlText: '#444',
    },
    segment: {
      backgroundColor: '#ffffffee',
    },
  },

  // Dark mode
  dark: {
    text: '#444',
    background: '#151718',
    tint: tintColorDark,
    icon: '#9BA1A6',
    tabIconDefault: '#9BA1A6',
    tabIconSelected: tintColorDark,
    segment: {
      backgroundColor: '#001122dd',
    },
  },
};
