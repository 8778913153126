import distance from '@turf/distance';
import {
  Entity,
  EntityCollection,
  Scene,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer,
} from 'cesium';
import { LatLng } from '../../../types/types';
import { EntityType } from '../types';
import {
  entityPosInDegrees,
  getEntityType,
  pickResultAsEntity,
  screenSpaceCoordToDeg,
} from './entities';
import createRoutePointEntity from './entities/route-point';

type Props = {
  viewer: Viewer;
  routeEntities: EntityCollection;
  getRouteLocked: () => boolean;
  getRoutePath: () => [number, number][];
  updateRoutePoint: (
    oldPos: [number, number],
    newPos: [number, number]
  ) => void;
};

type DragState = {
  dragging: boolean;
  entity?: Entity;
  startPos?: [number, number];
  endPos?: [number, number];
};

export const attachDragHandler = ({
  viewer,
  routeEntities,
  getRouteLocked,
  getRoutePath,
  updateRoutePoint,
}: Props): ScreenSpaceEventHandler => {
  const handler = new ScreenSpaceEventHandler(viewer.canvas);

  const dragState: DragState = {
    dragging: false,
    entity: undefined,
    startPos: undefined,
    endPos: undefined,
  };
  const startDragging = (e: Entity, startPos: [number, number]) => {
    dragState.dragging = true;
    dragState.entity = e;
    dragState.startPos = startPos;
  };
  const dragTo = (endPos: [number, number]) => {
    if (dragState.dragging === false) {
      return;
    }
    if (!dragState.entity) {
      console.error('cannot drag undefined entity in drag handler');
      return;
    }
    const newEntity = createRoutePointEntity(
      { latitude: endPos[1], longitude: endPos[0] },
      false
    );
    routeEntities.remove(dragState.entity);
    dragState.entity = newEntity;
    dragState.endPos = endPos;
    routeEntities.add(newEntity);
  };
  const finishDragging = () => {
    if (dragState.dragging === false) {
      return;
    }
    if (!dragState.startPos || !dragState.endPos) {
      console.error(
        'cannot finish dragging when start or end position is undefined in drag handler'
      );
      return;
    }
    updateRoutePoint([...dragState.startPos], [...dragState.endPos]);
    dragState.dragging = false;
    dragState.entity = undefined;
    dragState.startPos = undefined;
    dragState.endPos = undefined;
  };

  const handleLeftDown = createLeftDownHandler(
    viewer.scene,
    getRouteLocked,
    getRoutePath,
    startDragging
  );
  handler.setInputAction(handleLeftDown, ScreenSpaceEventType.LEFT_DOWN);

  const handleMouseMove = createMouseMoveHandler(
    viewer.scene,
    dragState,
    dragTo
  );
  handler.setInputAction(handleMouseMove, ScreenSpaceEventType.MOUSE_MOVE);

  const handleLeftUp = createLeftUpHandler(viewer.scene, finishDragging);
  handler.setInputAction(handleLeftUp, ScreenSpaceEventType.LEFT_UP);

  return handler;
};

const createLeftDownHandler = (
  scene: Scene,
  getRouteLocked: () => boolean,
  getRoutePath: () => [number, number][],
  startDragging: (e: Entity, startPos: [number, number]) => void
) => {
  const lockCamera = () => {
    const c = scene.screenSpaceCameraController;
    c.enableLook = false;
    c.enableRotate = false;
    c.enableTilt = false;
    c.enableTranslate = false;
  };

  const findClosestPoint = (pos: LatLng, path: [number, number][]) => {
    const closest = {
      point: path[0],
      distanceMeters: Infinity,
    };
    const p1 = [pos.longitude, pos.latitude];
    for (const p2 of path) {
      const dist = distance(p1, p2, { units: 'meters' });
      if (dist < closest.distanceMeters) {
        closest.point = p2;
        closest.distanceMeters = dist;
      }
    }
    return closest;
  };

  return (event: ScreenSpaceEventHandler.PositionedEvent) => {
    const routeLocked = getRouteLocked();
    if (routeLocked) {
      return;
    }

    const routePath = getRoutePath();
    if (routePath.length === 0) {
      return;
    }

    const pickResult = scene.pick(event.position);
    const entity = pickResultAsEntity(pickResult);
    if (!entity) {
      return;
    }

    const isRoutePoint = getEntityType(entity) === EntityType.RoutePoint;
    if (!isRoutePoint) {
      return;
    }

    const pos = entityPosInDegrees(entity);
    if (pos === undefined) {
      return;
    }

    const routePoint = findClosestPoint(pos, routePath);
    lockCamera();
    startDragging(entity, routePoint.point);
  };
};

const createMouseMoveHandler = (
  scene: Scene,
  dragState: DragState,
  dragTo: (endPos: [number, number]) => void
) => {
  return (event: ScreenSpaceEventHandler.MotionEvent) => {
    if (!dragState.entity) {
      return;
    }

    const pos = screenSpaceCoordToDeg(scene, event.endPosition);
    if (!pos) {
      return;
    }

    dragTo([pos.longitude, pos.latitude]);
  };
};

const createLeftUpHandler = (scene: Scene, finishDragging: () => void) => {
  const unlockCamera = () => {
    const c = scene.screenSpaceCameraController;
    c.enableLook = true;
    c.enableRotate = true;
    c.enableTilt = true;
    c.enableTranslate = true;
  };

  return () => {
    unlockCamera();
    finishDragging();
  };
};
