import { css } from '@emotion/css';
import Pano from '@montiscorp/pano';
import useStore from '../../../state/state';

type Props = {
  stationId: number;
  latitude: number;
  longitude: number;
};

export default function PanoramaTab({ stationId }: Props) {
  const aircraft = useStore((store) => store.aircraft);

  // const stationPos = [longitude, latitude];
  // const labels: LabelConfig[] = aircraft
  //   .map((a) => {
  //     const dist = distance(stationPos, [a.lon, a.lat], {
  //       units: 'kilometers',
  //     });
  //     // console.log(dist, stationPos, a);
  //     if (dist < 15) {
  //       return {
  //         name: a.flight,
  //         elevation: a.altitude,
  //         latitude: a.lat,
  //         longitude: a.lon,
  //       };
  //     }
  //   })
  //   .filter((x) => !!x);

  // TODO: These are hard coded for Merrill (1) and Rampart (2)
  if (stationId !== 1 && stationId !== 2) {
    return false;
  }

  return (
    <div className={styles}>
      <Pano
        stationId={stationId}
        config={{
          // @ts-expect-error Need to fix types in pano lib
          controls: {
            showStationInfo: false,
            showAzimuthIndicator: true,
            showElevationIndicator: true,
            showPanoPicker: false,
            showWarning: false,
            showZoomSlider: true,
            showPanSlider: false,
          },
        }}
        getAircraft={() => aircraft}
      />
    </div>
  );
}

const styles = css({
  aspectRatio: 2,
  width: '100%',
  position: 'relative',
  minHeight: '60vh',
});
