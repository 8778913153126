import IconLocate from '../../svg-icons/Locate';
import Button from './Button';

type Props = {
  onClick: () => void;
};

export default function LocateButton({ onClick }: Props) {
  return <Button icon={<IconLocate />} shape="round" onClick={onClick} />;
}
