import { css } from '@emotion/css';

import { useCallback, useEffect, useState } from 'react';
import { deleteUserStation, saveUserStation } from '../../api';
import { colors } from '../../constants/colors';
import useStore from '../../state/state';
import { StationId, StationType } from '../../types/types';
import CameraSiteModal from './station/CameraSiteModal';
import MwosModal from './station/MwosModal';
import WeatherStationModal from './station/WeatherStationModal';

type Props = {
  isVisible: boolean;
  close: () => void;
  stationId?: StationId;
  stationType?: StationType;
};

export default function StationModal({
  isVisible,
  close,
  stationId,
  stationType,
}: Props) {
  const userSavedStations = useStore((store) => store.userSavedStations);
  const refreshUserSavedStations = useStore(
    (store) => store.refreshUserSavedStations
  );
  const [saved, setSaved] = useState<boolean>(false);

  useEffect(() => {
    if (!stationId) {
      return;
    }
    if (userSavedStations === undefined) {
      refreshUserSavedStations();
      return;
    }
    setSaved(userSavedStations.map((s) => s.stationId).includes(stationId));
  }, [refreshUserSavedStations, stationId, userSavedStations]);

  const save = useCallback(
    async (stationType: StationType) => {
      if (!stationId) {
        return;
      }
      try {
        await saveUserStation(stationId, stationType);
        setSaved(true);
        await refreshUserSavedStations();
      } catch (err) {
        console.error('error saving user station', err);
      }
    },
    [stationId, refreshUserSavedStations]
  );

  const unsave = useCallback(async () => {
    if (!stationId) {
      return;
    }
    if (userSavedStations === undefined) {
      return;
    }
    try {
      const userStation = userSavedStations.find(
        (s) => s.stationId === stationId
      );
      if (!userStation) {
        throw new Error('could not find matching user station');
      }
      await deleteUserStation(userStation.id);
      setSaved(false);
      await refreshUserSavedStations();
    } catch (err) {
      console.error('error deleting user station', err);
    }
  }, [stationId, userSavedStations, refreshUserSavedStations]);

  if (!isVisible || !stationId) {
    return false;
  }

  if (stationType === 'mwos') {
    return (
      <div className={styles}>
        <div className="backdrop" />
        <MwosModal
          key={`modal-mwos-${stationId}`}
          stationId={stationId}
          close={close}
          isSaved={saved}
          save={() => save('mwos')}
          unsave={unsave}
        />
      </div>
    );
  }

  if (stationType === 'faa_camera_site') {
    return (
      <div className={styles}>
        <div className="backdrop" />
        <CameraSiteModal
          key={`modal-cam-${stationId}`}
          stationId={stationId}
          close={close}
          isSaved={saved}
          save={() => save('faa_camera_site')}
          unsave={unsave}
        />
      </div>
    );
  }

  return (
    <div className={styles}>
      <div className="backdrop" />
      <WeatherStationModal
        key={`modal-ws-${stationId}`}
        stationId={stationId}
        close={close}
        isSaved={saved}
        save={() => save('weather_station')}
        unsave={unsave}
      />
    </div>
  );
}

const styles = css({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  alignContent: 'center',
  pointerEvents: 'none',

  '.backdrop': {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },

  '.modal': {
    pointerEvents: 'all',
    borderRadius: 5,
    width: 'min(75vh, 100vw)',
    maxHeight: '100vh',
    minWidth: '35%',
    aspectRatio: 1.5,
    zIndex: 2,
    animation: 'slideUp .5s',
    '@keyframes slideUp': {
      '0%': {
        opacity: 0,
        transform: 'translate(0,50px)',
      },
      '100%': {
        opacity: 1,
        transform: 'translate(0,0)',
      },
    },
    '.image-wrapper': {
      display: 'block',
      img: {
        width: '100%',
      },
    },
    '.sub': {
      textAlign: 'center',
      width: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: '#afe9af',
      fontSize: '1em',
      '@media (max-width: 600px)': {
        fontSize: '.75em',
      },
    },
  },

  '.header': {
    '.save-button': {
      width: '1em',
      height: '1em',
      marginRight: '.5em',
      color: colors.primaryLight,
      transform: 'translateY(.1em)',
      cursor: 'pointer',
      ':hover::after': {
        display: 'block',
        content: '"Save station"',
        position: 'absolute',
      },
    },
    backgroundColor: '#ffffffee',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: 'relative',
    padding: '.75em',
    '.controls': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '.75em',
      position: 'absolute',
      top: 0,
      right: 0,
      '.close-button': {
        width: '2em',
        height: '2em',
        cursor: 'pointer',
        ':hover': {
          opacity: 0.65,
        },
      },
    },
    '.title': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '2em',
      color: '#222',
      fontWeight: 'bold',
      '@media (max-width: 600px)': {
        fontSize: '1em',
      },
    },
  },
});
