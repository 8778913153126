import { ArcType, Cartesian3, Color, Entity, MaterialProperty } from 'cesium';
import { LatLng } from '../../../../types/types';

export default function createPolylineEntity(
  points: LatLng[],
  width: number = 5,
  color: string = '#ffffff',
  material?: MaterialProperty
) {
  return new Entity({
    polyline: {
      clampToGround: true,
      positions: Cartesian3.fromDegreesArray(
        points.flatMap((p) => [p.longitude, p.latitude])
      ),
      zIndex: 0,
      width,
      arcType: ArcType.GEODESIC,
      material: material ?? Color.fromCssColorString(color),
    },
  });
}
