import {
  Cartesian2,
  Cartesian3,
  Color,
  Entity,
  HeightReference,
  LabelStyle,
  VerticalOrigin,
} from 'cesium';
import routePoint from '../../../../assets/images/route_point.svg';
import { LatLng } from '../../../../types/types';
import { EntityType } from '../../types';
import {
  billboardScaleByDistance,
  billboardTranslucencyByDistance,
  labelScaleByDistance,
  labelTranslucencyByDistance,
} from './common';

export default function createRoutePointEntity(
  pos: LatLng,
  routeIsLocked: boolean,
  radius?: number
): Entity {
  return new Entity({
    position: Cartesian3.fromDegrees(pos.longitude, pos.latitude),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: 1e6,
      image: routePoint,
      width: radius ?? routeIsLocked ? 20 : 42,
      height: radius ?? routeIsLocked ? 20 : 42,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: '',
    label: {
      text: `${pos.latitude.toFixed(3)},${pos.longitude.toFixed(3)}`,
      font: 'bold 13pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(0, 28),
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: 1e6,
      // disableDepthTestDistance: Number.POSITIVE_INFINITY,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      entityType: EntityType.RoutePoint,
    },
  });
}
