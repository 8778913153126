import { css } from '@emotion/css';
import { ReactNode } from 'react';
import { colors } from '../constants/colors';
import IconChevronRight from './svg-icons/ChevronRight';

type Props = {
  screenSide: 'left' | 'right';
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
};

export default function Drawer({
  screenSide,
  title,
  isOpen,
  onClose,
  children,
  className = '',
  icon,
}: Props) {
  if (!isOpen) {
    return false;
  }

  if (screenSide === 'left') {
    className = `${style} ${leftSideStyle} ${className}`;
  } else {
    className = `${style} ${rightSideStyle} ${className}`;
  }

  const closeIcon =
    screenSide === 'left' ? (
      <IconChevronRight width={32} height={32} />
    ) : (
      <IconChevronRight width={32} height={32} />
    );

  return (
    <div className={`${className} ${isOpen ? 'open' : ''}`}>
      <div className="drawer-header">
        {screenSide === 'left' && (
          <span className="drawer-title">
            {icon && <span className="icon">{icon}</span>} {title}
          </span>
        )}
        <button onClick={onClose}>{closeIcon}</button>
        {screenSide === 'right' && (
          <span className="drawer-title">
            {title}
            {icon && <span className="icon">{icon}</span>}{' '}
          </span>
        )}
      </div>
      <div className="content">{children}</div>
    </div>
  );
}

const style = css({
  width: 400,
  maxWidth: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  backgroundColor: '#fffffff2',
  zIndex: 2,
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  '.drawer-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5em',
    '.drawer-title': {
      fontSize: '1.75em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 10,
    },
    button: {
      outline: 'none',
      border: 'none',
      fontSize: 18,
      background: 'none',
      fontWeight: 600,
      borderRadius: 3,
      ':hover': {
        opacity: 0.75,
        cursor: 'pointer',
        backgroundColor: '#eee',
        color: colors.primary,
      },
      paddingTop: '.25em',
    },
  },
  '.content': {
    overflow: 'auto',
  },
});

const rightSideStyle = css({
  right: 0,
  animation: 'fadeInRight .25s',
  '@keyframes fadeInRight': {
    '0%': {
      transform: 'translate(400px,0px)',
    },
    '100%': {
      transform: 'translate(0px,0px)',
    },
  },
  '.drawer-header .drawer-title .icon': {
    marginLeft: '.5em',
  },
});

const leftSideStyle = css({
  left: 0,
  animation: 'fadeInLeft .25s',
  '@keyframes fadeInLeft': {
    '0%': {
      transform: 'translate(-400px,0px)',
    },
    '100%': {
      transform: 'translate(0px,0px)',
    },
  },
  '.drawer-header': {
    button: {
      svg: {
        transform: 'rotateZ(180deg)',
      },
    },
    '.drawer-title .icon': {
      marginRight: '.5em',
    },
  },
});
