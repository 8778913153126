import { css } from '@emotion/css';
import { CSSProperties } from 'react';
import { Camera, MwosCamera } from '../../../api/types';
import { colors } from '../../../constants/colors';
import { minutesAgo } from './functions';

type Props = {
  cameras: Camera[] | MwosCamera[];
  isMwos: boolean;
  onClick: (c: Camera | MwosCamera) => void;
};

export default function AllImages({ cameras, isMwos, onClick }: Props) {
  return (
    <div className={styles}>
      {cameras &&
        cameras
          .filter((c) => c.currentImageUrl)
          .map((camera, i) =>
            isMwos ? (
              <MwosImage
                key={i}
                camera={camera as MwosCamera}
                onClick={onClick}
              />
            ) : (
              <FaaImage key={i} camera={camera as Camera} onClick={onClick} />
            )
          )}
    </div>
  );
}

type MwosImageProps = {
  camera: MwosCamera;
  onClick: (c: MwosCamera) => void;
};

const MwosImage = ({ camera, onClick }: MwosImageProps) => {
  return (
    <div className="image-wrapper">
      <img src={camera.currentImageUrl} onClick={() => onClick(camera)} />
      <span className="sub">
        {camera.directionText} ({minutesAgo(camera.currentImageObservationTime)}{' '}
        min ago)
      </span>
    </div>
  );
};

type FaaImageProps = {
  camera: Camera;
  onClick: (c: Camera) => void;
};

const FaaImage = ({ camera, onClick }: FaaImageProps) => {
  const minAgo = minutesAgo(camera.cameraLastSuccess);
  const style: CSSProperties = {};
  if (minAgo > 60) {
    style.backgroundColor = colors.danger;
  } else if (minAgo > 20) {
    style.backgroundColor = colors.warning;
  }

  return (
    <div className="image-wrapper">
      <img src={camera.currentImageUrl} onClick={() => onClick(camera)} />
      <span className="sub" style={style}>
        {camera.cameraDirection} ({minutesAgo(camera.cameraLastSuccess)} min
        ago)
      </span>
    </div>
  );
};

const styles = css({
  backgroundColor: '#ffffffdd',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 2,
  rowGap: 2,
  padding: 2,
  img: {
    cursor: 'pointer',
  },
  '.images-wrapper': {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
  },
});
