import { css } from '@emotion/css';
import { radiansToDegrees } from '@turf/helpers';
import compassImg from '../../../assets/images/compass.svg';
import useStore from '../../../state/state';

type Props = {
  onClick: () => void;
};

export default function Compass({ onClick }: Props) {
  const heading = useStore((store) => store.heading);
  const orientationDegrees = radiansToDegrees(heading);
  return (
    <div
      className={style}
      style={{
        transform: `rotateZ(-${orientationDegrees}deg)`,
      }}
      onClick={onClick}
    >
      <img src={compassImg} />
    </div>
  );
}

const style = css({
  // position: 'absolute',
  width: 60,
  height: 60,
  bottom: 10,
  right: 10,
  backgroundColor: '#ffffff77',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 75,
  color: '#111',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: '#ffffff99',
  },
  img: {
    height: '95%',
  },
});
