import 'cesium/Build/Cesium/Widgets/widgets.css';
import { useEffect, useState } from 'react';
import useGeolocation from '../../hooks/useGeolocation';
import useStore from '../../state/state';
import Controls from './controls/Controls';
import Map from './Map';

export default function MapView() {
  const [setCorridor, setSetCorridor] = useState({});
  const [doFlyTo, setFlyTo] = useState({});
  const [doUndo, setDoUndo] = useState({});
  const [setHeading, setSetHeading] = useState({});
  const [, setSetRoute] = useState({});
  const [setLocked, setSetLocked] = useState({});
  const mapUndoActions = useStore((store) => store.mapUndoActions);
  const setMapUndoActions = useStore((store) => store.setMapUndoActions);

  const userLocation = useStore((store) => store.userLocation);
  const setUserLocation = useStore((store) => store.setUserLocation);

  const { location } = useGeolocation();
  useEffect(() => {
    if (!userLocation && location) {
      setUserLocation(location);
    }
  });

  // Merrill Field MWOS
  const defaultPosition = {
    latitude: 61.21675013797042,
    longitude: -149.83343375728631,
  };

  return (
    <>
      <Map
        initialPosition={defaultPosition}
        setSetCorridor={setSetCorridor}
        setDoUndo={setDoUndo}
        setFlyTo={setFlyTo}
        setSetRoute={setSetRoute}
        setSetLocked={setSetLocked}
        setSetHeading={setSetHeading}
        setUndoActions={setMapUndoActions}
      />
      <Controls
        setRouteLocked={setLocked}
        setCorridor={setCorridor}
        undo={doUndo}
        undoActions={mapUndoActions}
        flyTo={doFlyTo}
        setHeading={setHeading}
      />
    </>
  );
}
