import { radiansToDegrees } from '@turf/helpers';
import {
  Cartesian2,
  Cartographic,
  Math as CesiumMath,
  Ellipsoid,
  Entity,
  EntityCollection,
  PositionProperty,
  Scene,
} from 'cesium';
import { FlightCategory, LatLng } from '../../../types/types';

import { Route } from '../../../api/types';
import { EntityType } from '../types';
import createRouteCorridorEntity from './entities/route-corridor';
import createRouteLineEntity from './entities/route-line';
import createRoutePointEntity from './entities/route-point';

export const entityPosInDegrees = (e: Entity): LatLng | undefined => {
  const pos = (e.position as PositionProperty).getValue();
  if (pos === undefined) {
    console.error('entity has no position property', e);
    return undefined;
  }
  const cartographicPos = Cartographic.fromCartesian(pos);
  return {
    latitude: radiansToDegrees(cartographicPos.latitude),
    longitude: radiansToDegrees(cartographicPos.longitude),
  };
};

export const createRouteEntities = (route: Route) => {
  const points = route.path.map((p) =>
    createRoutePointEntity({ latitude: p[1], longitude: p[0] }, false)
  );
  const line = createRouteLineEntity(route.path);
  const corridor = createRouteCorridorEntity(
    route.path,
    route.corridorWidthMeters
  );
  return {
    points,
    line,
    corridor,
  };
};

export const screenSpaceCoordToDeg = (
  scene: Scene,
  position: Cartesian2
): LatLng => {
  const scenePos = scene.pickPosition(position);

  // The following only works with globe = true
  // const ray = viewer.camera.getPickRay(position) as Ray;
  // const globePos = viewer.scene.globe.pick(ray, viewer.scene);
  // console.log(globePos);

  const earth = Ellipsoid.WGS84;
  const pos = earth.cartesianToCartographic(scenePos);

  const degPos = {
    latitude: CesiumMath.toDegrees(pos.latitude),
    longitude: CesiumMath.toDegrees(pos.longitude),
  };

  return degPos;
};

export const getEntityType = (e: Entity): EntityType | undefined => {
  if (!e.properties) {
    return undefined;
  }
  const t = e.properties.entityType;
  if (!t) {
    return undefined;
  }
  return t.valueOf() as EntityType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pickResultAsEntity = (pickResult: any): Entity | undefined => {
  if (!pickResult) {
    return undefined;
  }
  if (!pickResult.primitive || !pickResult.id) {
    return undefined;
  }
  return pickResult.id as Entity;
};

export const entitiesWithFlightCategory = (
  entities: EntityCollection,
  flightCategory?: FlightCategory
) => {
  if (flightCategory === undefined) {
    return entities.values.filter(
      (e) => e.properties?.stationDetails?.getValue().metar === undefined
    );
  }
  return entities.values.filter(
    (e) =>
      e.properties?.stationDetails.getValue().metar?.flightCategory ===
      flightCategory
  );
};
