import centerOfMass from '@turf/center-of-mass';
import distance from '@turf/distance';
import { lineString, point } from '@turf/helpers';
import {
  Cartesian2,
  Color,
  EntityCollection,
  PolylineDashMaterialProperty,
  Viewer,
} from 'cesium';
import { MeasureDistanceState } from '../../../state/state';
import { milesToKm } from '../../../utils/map';
import { screenSpaceCoordToDeg } from './entities';
import createAircraftEntity from './entities/aircraft';
import createPolylineEntity from './entities/polyline';
import createRoutePointEntity from './entities/route-point';

export const addMeasureDistancePoint = (
  viewer: Viewer,
  position: Cartesian2,
  entities: EntityCollection,
  state: MeasureDistanceState
) => {
  const posDegrees = screenSpaceCoordToDeg(viewer.scene, position);
  state.points.push(posDegrees);

  entities.removeAll();

  state.points.map((p) => {
    const e = createRoutePointEntity(p, false, 10);
    entities.add(e);
  });

  const line = createPolylineEntity(
    state.points,
    3,
    undefined,
    new PolylineDashMaterialProperty({
      color: Color.fromCssColorString('#ffffff99'),
      gapColor: Color.TRANSPARENT,
      dashLength: 5,
    })
  );
  entities.add(line);

  if (state.points.length < 2) {
    return {
      totalDistance: 0,
    };
  }

  let totalDistance = 0;
  for (let i = 0; i < state.points.length - 1; i += 1) {
    const p1 = state.points[i];
    const p2 = state.points[i + 1];

    const dist = distance(
      point([p1.longitude, p1.latitude]),
      point([p2.longitude, p2.latitude]),
      { units: 'miles' }
    );
    totalDistance += dist;
    const center = centerOfMass(
      lineString([
        [p1.longitude, p1.latitude],
        [p2.longitude, p2.latitude],
      ])
    );

    const miles = dist.toFixed(2);
    const km = milesToKm(dist).toFixed(2);

    const distanceLabel = createAircraftEntity({
      label: `${miles} mi (${km} km)`,
      longitude: center.geometry.coordinates[0],
      latitude: center.geometry.coordinates[1],
      altitude: 100,
      color: Color.TRANSPARENT,
    });
    entities.add(distanceLabel);
  }

  return {
    totalDistance,
  };
};
