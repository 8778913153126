import { ArcType, Cartesian3, Color, Entity } from 'cesium';
import { EntityType } from '../../types';

export default function createRouteLineEntity(
  path: [number, number][]
): Entity {
  return new Entity({
    name: 'Route',
    polyline: {
      clampToGround: true,
      positions: Cartesian3.fromDegreesArray(path.flat()),
      zIndex: 0,
      width: 7,
      arcType: ArcType.GEODESIC,
      material: Color.fromCssColorString('#2f65bfee'),
    },
    properties: {
      entityType: EntityType.RouteLine,
    },
  });
}
