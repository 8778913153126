import { MwosObservation } from '../../../api/types';
import { default as Row } from './DecodedWeatherRow';
import { displayDatetime, minutesAgo } from './functions';

type MwosDecodedWeatherProps = {
  observation: MwosObservation;
};

export default function MwosDecodedWeather({
  observation,
}: MwosDecodedWeatherProps) {
  const c2f = (c: number) => (c * (9.0 / 5.0) + 32).toFixed(1);
  const fmtC = (deg: number) => `${deg.toFixed(2)} °C (${c2f(deg)} °F)`;

  return (
    <div style={{ backgroundColor: '#ffffffee', paddingBottom: 20 }}>
      <Row
        label="Observation Time"
        value={`${displayDatetime(observation.observationTime)} (${minutesAgo(
          observation.observationTime
        )} min ago)`}
      />
      <Row label="Temperature" value={fmtC(observation.tempC)} />
      <Row label="Dew Point" value={fmtC(observation.dewpointC)} />
      <Row label="Pressure" value={`${observation.pressureHpa} inHg`} />
      <Row label="Winds" value={observation.windsText} />
      <Row label="Humidity" value={`${observation.humidityPct.toFixed(2)} %`} />
      <Row
        label="Wet Bulb Temperature"
        value={fmtC(observation.wetbulbTempC)}
      />
      <Row label="Precipitation Type" value={observation.precipType} />
      <Row label="Precipitation Rate" value={observation.precipIn} />
    </div>
  );
}
