import {
  Cartesian2,
  Cartesian3,
  Color,
  Entity,
  HeightReference,
  LabelStyle,
  VerticalOrigin,
} from 'cesium';
import { SiteDetails, StationDetails } from '../../../../api/types';

import ifr from '../../../../assets/images/station_ifr.svg';
import ifrCamera from '../../../../assets/images/station_ifr_cam.svg';
import lifr from '../../../../assets/images/station_lifr.svg';
import lifrCamera from '../../../../assets/images/station_lifr_cam.svg';
import mvfr from '../../../../assets/images/station_mvfr.svg';
import mvfrCamera from '../../../../assets/images/station_mvfr_cam.svg';
import plain from '../../../../assets/images/station_plain.svg';
import plainCamera from '../../../../assets/images/station_plain_cam.svg';
import vfr from '../../../../assets/images/station_vfr.svg';
import vfrCamera from '../../../../assets/images/station_vfr_cam.svg';
import { EntityType } from '../../types';
import {
  billboardScaleByDistance,
  billboardTranslucencyByDistance,
  labelPixelOffsetByDistance,
  labelScaleByDistance,
  labelTranslucencyByDistance,
} from './common';

export default function createStationEntity(
  station: StationDetails | SiteDetails
): Entity {
  let image;
  if (!station.flightCategory) {
    if (station.stationType === 'faa_camera_site') {
      image = plainCamera;
    } else {
      image = plain;
    }
  } else {
    if (station.stationType === 'faa_camera_site') {
      switch (station.flightCategory) {
        case 'VFR':
          image = vfrCamera;
          break;
        case 'IFR':
          image = ifrCamera;
          break;
        case 'MVFR':
          image = mvfrCamera;
          break;
        case 'LIFR':
          image = lifrCamera;
          break;
      }
    } else {
      switch (station.flightCategory) {
        case 'VFR':
          image = vfr;
          break;
        case 'IFR':
          image = ifr;
          break;
        case 'MVFR':
          image = mvfr;
          break;
        case 'LIFR':
          image = lifr;
          break;
      }
    }
  }

  let name;
  if (station.stationType === 'faa_camera_site') {
    const s = station as SiteDetails;
    name = s.icao || s.siteName;
  } else {
    const s = station as StationDetails;
    name = s.icaoId || s.siteName;
  }

  return new Entity({
    position: Cartesian3.fromDegrees(station.longitude, station.latitude, 50),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      image: image,
      width: 48,
      height: 48,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: name,
    label: {
      text: name,
      font: 'bold 16pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.BASELINE,
      pixelOffset: new Cartesian2(0, 45),
      pixelOffsetScaleByDistance: labelPixelOffsetByDistance,
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      scaleByDistance: labelScaleByDistance,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      stationDetails: station,
      entityType: EntityType.WeatherStation,
    },
  });
}
