import { useCallback, useEffect, useState } from 'react';
import { memoGetSiteDetails, memoGetStationDetails } from '../api';
import { SiteDetails, StationDetails } from '../api/types';

export default function useCameraSiteDetails(
  siteId: number,
  refreshMs?: number
) {
  const [siteDetails, setSiteDetails] = useState<SiteDetails>();
  const [stationDetails, setStationDetails] = useState<StationDetails>();

  const getSiteDetails = useCallback(async () => {
    const s = await memoGetSiteDetails(siteId);
    setSiteDetails(s);
    if (s.stationId) {
      const st = await memoGetStationDetails(s.stationId);
      setStationDetails(st);
    }
  }, [siteId]);

  useEffect(() => {
    if (!siteDetails) {
      getSiteDetails();
    }
    if (refreshMs) {
      const intId = setInterval(getSiteDetails, refreshMs);
      return () => {
        clearInterval(intId);
      };
    }
  }, [siteDetails, getSiteDetails, refreshMs]);

  return {
    siteDetails,
    stationDetails,
  };
}
