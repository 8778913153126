import { useState } from 'react';
import { Camera, StationDetails } from '../../../api/types';
import useCameraSiteDetails from '../../../hooks/useCameraSiteDetails';
import { StationModalTabName } from '../../../types/types';
import IconClose from '../../svg-icons/Close';
import IconStar from '../../svg-icons/Star';
import IconStarOutline from '../../svg-icons/StarOutline';
import OverviewTab from './OverviewTab';
import Tabs from './Tabs';
import WeatherTab from './WeatherTab';

type Props = {
  stationId: number;
  isSaved: boolean;
  close: () => void;
  save: () => void;
  unsave: () => void;
};

export default function CameraSiteModal({
  stationId,
  isSaved,
  save,
  unsave,
  close,
}: Props) {
  const [currentTab, setCurrentTab] = useState<StationModalTabName>('overview');
  const { siteDetails, stationDetails } = useCameraSiteDetails(stationId, 30e3);

  if (!siteDetails) {
    return;
  }

  const title = `${siteDetails.siteName} (${
    siteDetails.icao || siteDetails.siteIdentifier
  })`;
  const onTabClick = (tabName: StationModalTabName) => setCurrentTab(tabName);

  return (
    <div className="modal">
      <div className="header">
        <div className="title">
          {isSaved ? (
            <IconStar className="save-button" onClick={unsave} />
          ) : (
            <IconStarOutline className="save-button" onClick={save} />
          )}
          {title}
        </div>
        <div className="controls">
          <IconClose
            className="close-button"
            onClick={() => {
              close();
            }}
          />
        </div>
      </div>
      <Tabs
        currentTab={currentTab}
        onClick={onTabClick}
        isMwos={false}
        hasWeather={stationDetails?.metar !== undefined}
      />
      {currentTab === 'overview' && (
        <OverviewTab
          station={siteDetails}
          cameras={siteDetails.cameras as Camera[]}
          isMwos={false}
        />
      )}
      {currentTab === 'weather' && (
        <WeatherTab station={stationDetails as StationDetails} isMwos={false} />
      )}
    </div>
  );
}
