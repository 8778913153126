import { css } from '@emotion/css';
import { colors } from '../../../constants/colors';

type CameraTabsProps = {
  labels: string[];
  currentTab?: string;
  onClickTab: (label?: string) => void;
};

export default function CameraTabs({
  labels,
  currentTab,
  onClickTab,
}: CameraTabsProps) {
  return (
    <nav className={styles}>
      <ul>
        <li
          className={currentTab === undefined ? 'active' : undefined}
          onClick={() => onClickTab()}
        >
          <label>All</label>
        </li>
        {labels.map((l, i) => (
          <li
            key={`tab-${i}`}
            className={l === currentTab ? 'active' : undefined}
            onClick={() => onClickTab(l)}
          >
            <label>{l}</label>
          </li>
        ))}
      </ul>
    </nav>
  );
}

const styles = css({
  backgroundColor: '#ffffffee',
  paddingBottom: '1em',
  ul: {
    borderBottom: '1px solid #aaa',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 .75em',

    li: {
      paddingBottom: '.35em',
      label: {
        display: 'block',
        padding: '.25em .5em',
        fontSize: '1.3em',
        fontWeight: 500,
        color: '#333',
        cursor: 'pointer',
        borderRadius: 5,
        ':hover': {
          color: '#111',
          backgroundColor: '#0000000d',
        },
      },
      marginRight: '1em',
      '&.active': {
        borderBottom: `4px solid ${colors.primaryLight}`,
        label: {
          color: '#111',
          fontWeight: 600,
        },
      },
      '@media (max-width: 600px)': {
        label: {
          fontSize: '.8em',
          padding: '0 .15em',
        },
        marginRight: '0.5em',
      },
    },
  },
});
