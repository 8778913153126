import { css } from '@emotion/css';
import { useState } from 'react';
import useMwosDetails from '../../../hooks/useMwosDetails';
import { StationModalTabName } from '../../../types/types';
import IconClose from '../../svg-icons/Close';
import IconStar from '../../svg-icons/Star';
import IconStarOutline from '../../svg-icons/StarOutline';
import OverviewTab from './OverviewTab';
import PanoramaTab from './PanoramaTab';
import Tabs from './Tabs';
import WeatherTab from './WeatherTab';

type Props = {
  stationId: number;
  isSaved: boolean;
  close: () => void;
  save: () => void;
  unsave: () => void;
};

export default function MwosModal({
  stationId,
  isSaved,
  save,
  unsave,
  close,
}: Props) {
  const [currentTab, setCurrentTab] = useState<StationModalTabName>('panorama');
  const { details } = useMwosDetails(stationId, 30e3);

  if (!details) {
    return;
  }

  const title = `${details.siteName} (${details.icaoId})`;
  const onTabClick = (tabName: StationModalTabName) => setCurrentTab(tabName);

  return (
    <div className={`modal ${currentTab === 'panorama' ? panoModalStyle : ''}`}>
      <div className="header">
        <div className="title">
          {isSaved ? (
            <IconStar className="save-button" onClick={unsave} />
          ) : (
            <IconStarOutline className="save-button" onClick={save} />
          )}
          {title}
        </div>
        <div className="controls">
          <IconClose
            className="close-button"
            onClick={() => {
              close();
            }}
          />
        </div>
      </div>
      <Tabs
        currentTab={currentTab}
        onClick={onTabClick}
        isMwos
        hasWeather={details.observations?.length > 0}
      />
      {currentTab === 'overview' && (
        <OverviewTab station={details} cameras={details.cameras} isMwos />
      )}
      {currentTab === 'weather' && <WeatherTab station={details} isMwos />}
      {currentTab === 'panorama' && (
        <PanoramaTab
          stationId={stationId}
          latitude={details.latitude}
          longitude={details.longitude}
        />
      )}
    </div>
  );
}

const panoModalStyle = css({
  width: '60vw !important',
  '@media (max-width: 600px)': {
    minWidth: '100vw !important',
  },
});
