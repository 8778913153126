import { useCallback, useEffect, useState } from 'react';
import { memoGetMwosDetails } from '../api';
import { MwosDetails } from '../api/types';

export default function useMwosDetails(stationId: number, refreshMs?: number) {
  const [details, setDetails] = useState<MwosDetails>();

  const getDetails = useCallback(async () => {
    const s = await memoGetMwosDetails(stationId);
    setDetails(s);
  }, [stationId]);

  useEffect(() => {
    if (!details) {
      getDetails();
    }
    if (refreshMs) {
      const intId = setInterval(getDetails, refreshMs);
      return () => clearInterval(intId);
    }
  }, [refreshMs, getDetails, details]);

  return {
    details,
  };
}
