import { NearFarScalar } from 'cesium';

export const billboardScaleByDistance = new NearFarScalar(1.2e3, 1.0, 2e6, 0.1);
export const billboardTranslucencyByDistance = new NearFarScalar(
  2e5,
  1.0,
  1e8,
  0
);
export const labelScaleByDistance = new NearFarScalar(1.2e3, 1.0, 2e6, 0.25);
export const labelTranslucencyByDistance = new NearFarScalar(2e5, 1.0, 1e8, 0);
export const labelPixelOffsetByDistance = new NearFarScalar(
  1.2e3,
  1.0,
  2e6,
  0.25
);
