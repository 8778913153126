import {
  Authenticator,
  ThemeProvider,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { css } from '@emotion/css';
import { useEffect } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import planeBgImage from '../assets/hero.avif';
import { colors } from '../constants/colors';

export default function SignIn() {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      redirect('/map');
    }
  }, [authStatus]);

  return (
    <ThemeProvider
      theme={{
        name: 'default',
        tokens: {
          colors: {
            primary: {
              80: colors.primaryLight,
            },
            secondary: {
              80: colors.primary,
            },
          },
        },
      }}
    >
      <div className={style}>
        <Authenticator hideSignUp={true}>
          {() => <Navigate to="/map" replace={true} />}
        </Authenticator>
      </div>
    </ThemeProvider>
  );
}

const style = css({
  display: 'flex',
  position: 'absolute',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  background: `url(${planeBgImage}) no-repeat center center fixed`,
  backgroundSize: 'cover',
  '*': {
    fontFamily: 'Open Sans',
  },
});
