import { css } from '@emotion/css';
import { ChangeEvent, useState } from 'react';
import { colors } from '../../../constants/colors';
import { filterAircraft } from '../../../state/aircraft';
import useStore from '../../../state/state';
import { Aircraft } from '../../../types/types';
import Drawer from '../../Drawer';
import IconFilter16 from '../../svg-icons/Filter';
import IconPlaneTilt from '../../svg-icons/Plane';
import Button from './Button';

type Props = {
  isOpen: boolean;
  onSelect: (a: Aircraft) => void;
  onClose: () => void;
};

export default function AdsbDrawer({ isOpen, onSelect, onClose }: Props) {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const aircraft = useStore((store) => store.aircraft);
  const aircraftFilters = useStore((store) => store.aircraftFilters);
  const setAircraftFilters = useStore((store) => store.setAircraftFilters);

  const setCallsignFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setAircraftFilters({
      ...aircraftFilters,
      callsign: value.length > 0 ? value : undefined,
    });
  };

  const toggleFilters = () => setShowFilters((v) => !v);

  const filteredAircraft = filterAircraft(aircraft, aircraftFilters);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconPlaneTilt />}
      title="ADS-B"
      screenSide="left"
      className={style}
    >
      <div className="header">
        <span className="title">Tracked Aircraft</span>
        <Button
          className="button"
          text="Filters"
          icon={<IconFilter16 />}
          onClick={toggleFilters}
        />
      </div>
      {showFilters && (
        <div className="filters">
          <label>Callsign</label>
          <input
            type="text"
            onChange={setCallsignFilter}
            defaultValue={aircraftFilters.callsign}
          ></input>
        </div>
      )}
      <AircraftTable aircraft={filteredAircraft} onSelect={onSelect} />
    </Drawer>
  );
}

type TableProps = {
  aircraft: Aircraft[];
  onSelect: (a: Aircraft) => void;
};

const AircraftTable = ({ aircraft, onSelect }: TableProps) => {
  const ordered = aircraft.toSorted((a, b) => a.flight.localeCompare(b.flight));
  return (
    <table>
      <thead>
        <tr>
          <td>Callsign</td>
          <td>Hex</td>
          <td>Lat</td>
          <td>Lon</td>
          <td>Alt. (ft)</td>
          <td>Spd. (kt)</td>
        </tr>
      </thead>
      <tbody>
        {ordered.map((a) => {
          return (
            <tr key={a.hex} onClick={() => onSelect(a)}>
              <td>{a.flight}</td>
              <td>{a.hex}</td>
              <td>{a.lat.toFixed(2)}</td>
              <td>{a.lon.toFixed(2)}</td>
              <td>{a.altitude}</td>
              <td>{a.speed}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const style = css({
  '.header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.title': {
      fontSize: '1.5em',
      fontWeight: 600,
    },
    '.button': {
      '*': {
        cursor: 'pointer',
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      padding: 0,
      alignItems: 'center',
      width: '5.5em',
      height: '1.75em',
      backgroundColor: '#e9e9e9',
      fontWeight: 600,
      svg: {
        height: '1em',
        width: '1em',
      },
    },
  },

  '.filters': {
    padding: '0.5em 0',
    label: {
      marginRight: 5,
    },
  },

  table: {
    marginTop: 15,
    width: '100%',
    fontSize: '.9em',
    thead: {
      fontWeight: 600,
      backgroundColor: '#eee',
    },
    tbody: {
      td: {
        borderBottom: '1px solid #ccc',
      },
      tr: {
        ':hover': {
          backgroundColor: '#0000000a',
          color: colors.primaryLight,
          cursor: 'pointer',
        },
      },
    },
    td: {
      padding: '2px 3px',
    },
  },
});
