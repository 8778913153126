import { css } from '@emotion/css';
import { ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  text?: string;
  className?: string;
  onClick: () => void;
  shape?: 'square' | 'round';
};

export default function Button({
  icon,
  text,
  className,
  shape,
  onClick,
}: Props) {
  return (
    <div
      className={[styles, className || ''].join(' ')}
      onClick={onClick}
      style={
        shape === 'round'
          ? { borderRadius: '50%', padding: 0, width: 50, height: 50 }
          : undefined
      }
    >
      {icon}
      {text && <label>{text}</label>}
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  width: 65,
  height: 65,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 3,
  color: '#222',
  paddingTop: 5,
  svg: {
    width: 38,
    height: 38,
  },
  label: {
    fontSize: 15,
  },
  ':hover': {
    opacity: 0.85,
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {
    width: 50,
    height: 50,
    label: {
      fontSize: 11,
    },
  },
  animation: 'fadeIn .25s',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
});
