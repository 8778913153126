import { css } from '@emotion/css';
import { useCallback, useEffect } from 'react';
import { getUserRoutes } from '../../../api';
import { Route } from '../../../api/types';
import routesIcon from '../../../assets/images/route_light.svg';
import { colors } from '../../../constants/colors';
import useStore from '../../../state/state';
import { milesToMeters } from '../../../utils/map';
import Button from '../../Button';
import Drawer from '../../Drawer';
import IconAddOutline from '../../svg-icons/AddOutline';

type RoutesListProps = {
  routes: Route[];
  onSelect: (r: Route) => void;
};

const RoutesList = ({ routes, onSelect }: RoutesListProps) => {
  return (
    <ul>
      {routes.map((r) => (
        <li
          key={r.id}
          onClick={() => {
            onSelect(r);
          }}
        >
          {r.routeName}
        </li>
      ))}
    </ul>
  );
};

type RoutesDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (r: Route) => void;
};

export default function RoutesDrawer({
  isOpen,
  onClose,
  onSelect,
}: RoutesDrawerProps) {
  const userSavedRoutes = useStore((store) => store.userSavedRoutes);
  const setUserSavedRoutes = useStore((store) => store.setUserSavedRoutes);
  const setRouteState = useStore((store) => store.setRouteState);

  const loadRoutes = useCallback(async () => {
    const routes = await getUserRoutes();
    setUserSavedRoutes(routes);
  }, [setUserSavedRoutes]);

  useEffect(() => {
    if (userSavedRoutes.length === 0) {
      loadRoutes();
    }
  }, [loadRoutes, userSavedRoutes]);

  const onClickNewRoute = useCallback(() => {
    setRouteState({
      route: {
        path: [],
        corridorWidthMeters: milesToMeters(35),
      },
      editing: true,
      name: 'New Route',
      locked: false,
      isNewRoute: true,
    });
  }, [setRouteState]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      icon={<img src={routesIcon} style={{ width: '1em' }} />}
      title="Routes"
      screenSide="left"
      className={style}
    >
      <div className="controls">
        <Button
          primary
          icon={<IconAddOutline width="1.25em" height="1.25em" />}
          text={'New route'}
          onClick={onClickNewRoute}
        />
      </div>
      {userSavedRoutes.length > 0 ? (
        <RoutesList routes={userSavedRoutes} onSelect={onSelect} />
      ) : (
        <span>Loading routes...</span>
      )}
    </Drawer>
  );
}

const style = css({
  '.title': {
    fontSize: '1.5em',
    fontWeight: 600,
  },
  '.controls': {
    borderBottom: '1px solid #ddd',
    paddingBottom: '1em',
    display: 'flex',
    flexDirection: 'row',
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: 10,
    li: {
      fontWeight: 400,
      margin: 0,
      fontSize: '1.15em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '.25em .5em',
      borderRadius: 3,
      ':hover': {
        backgroundColor: '#0000000a',
        color: colors.primaryLight,
      },
    },
  },
});
