import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { getUserStations } from '../api';
import { Route, UserStation } from '../api/types';
import {
  Aircraft,
  LatLng,
  MapSettings,
  MapUndoAction,
  StationId,
  StationType,
} from '../types/types';
import {
  defaultMapSettings,
  defaultMeasureDistanceState,
  defaultRouteState,
} from './defaults';

export type RouteState = {
  editing: boolean;
  locked: boolean;
  name?: string;
  isNewRoute: boolean;
  route: {
    path: [number, number][];
    corridorWidthMeters: number;
  };
};

export type MeasureDistanceState = {
  editing: boolean;
  points: LatLng[];
  totalDistance: number;
};

export type ModalStation = {
  id: StationId;
  type: StationType;
};

export type AircraftFilters = {
  callsign?: string;
};

export type MapCoordinates = {
  latitude: number;
  longitude: number;
  heightMeters: number;
};

type Store = {
  aircraft: Aircraft[];
  setAircraft: (a: Aircraft[]) => void;
  aircraftFilters: AircraftFilters;
  setAircraftFilters: (f: AircraftFilters) => void;

  mapSettings: MapSettings;
  setMapSettings: (s: MapSettings) => void;
  mapUndoActions: MapUndoAction[];
  setMapUndoActions: (a: MapUndoAction[]) => void;

  userLocation?: LatLng;
  setUserLocation: (p: LatLng) => void;

  lastUserCoordinates?: MapCoordinates;
  setLastUserCoordinates: (c: MapCoordinates) => void;

  heading: number;
  setHeading: (h: number) => void;

  userSavedStations?: UserStation[];
  setUserSavedStations: (s: UserStation[]) => void;
  refreshUserSavedStations: () => Promise<void>;

  userSavedRoutes: Route[];
  setUserSavedRoutes: (r: Route[]) => void;

  routeState: RouteState;
  setRouteState: (s: RouteState) => void;

  measureDistanceState: MeasureDistanceState;
  setMeasureDistanceState: (s: MeasureDistanceState) => void;

  statusMessage?: string;
  setStatusMessage: (s?: string) => void;

  modalStation?: ModalStation;
  setModalStation: (s?: ModalStation) => void;
};

const useStore = create<Store>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        aircraft: [],
        setAircraft: (aircraft) => set({ aircraft }),

        aircraftFilters: {
          callsign: undefined,
        },
        setAircraftFilters: (f) => set({ aircraftFilters: f }),

        mapSettings: defaultMapSettings,
        setMapSettings: (mapSettings) => set({ mapSettings }),

        mapUndoActions: [],
        setMapUndoActions: (a: MapUndoAction[]) => set({ mapUndoActions: a }),

        userLocation: undefined,
        setUserLocation: (p) => set({ userLocation: p }),

        lastUserCoordinates: undefined,
        setLastUserCoordinates: (c) => set({ lastUserCoordinates: c }),

        heading: 0,
        setHeading: (heading) => set({ heading }),

        userSavedStations: undefined,
        setUserSavedStations: (s) => set({ userSavedStations: s }),
        refreshUserSavedStations: async () => {
          const s = await getUserStations();
          if (s?.length > 0) {
            s.sort((a, b) => a.displayOrder - b.displayOrder);
            set({ userSavedStations: s });
          }
        },

        userSavedRoutes: [],
        setUserSavedRoutes: (r) => set({ userSavedRoutes: r }),

        routeState: defaultRouteState,
        setRouteState: (s) => set({ routeState: s }),

        measureDistanceState: defaultMeasureDistanceState,
        setMeasureDistanceState: (s) => set({ measureDistanceState: s }),

        statusMessage: undefined,
        setStatusMessage: (s) => set({ statusMessage: s }),

        modalStation: undefined,
        setModalStation: (s) => set({ modalStation: s }),
      }),
      {
        name: 'local-storage',
        partialize: (state) => ({
          mapSettings: state.mapSettings,
          lastUserCoordinates: state.lastUserCoordinates,
        }),
      }
    )
  )
);

export default useStore;
