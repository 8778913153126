import { css } from '@emotion/css';
import { useCallback, useEffect, useState } from 'react';

import { saveUserRoute } from '../../../api';
import { getAircraft } from '../../../api/adsb';
import { Route, UserStation } from '../../../api/types';
import useStore from '../../../state/state';
import { Aircraft, LatLng, MapUndoAction } from '../../../types/types';
import { metersToMiles, milesToMeters } from '../../../utils/map';
import IconCheckmarkCircleOutline from '../../svg-icons/Checkmark';
import IconClose from '../../svg-icons/Close';
import StationModal from '../StationModal';
import AdsbButton from './AdsbButton';
import AdsbDrawer from './AdsbDrawer';
import Button from './Button';
import Compass from './Compass';
import CorriderSlider from './CorridorSlider';
import LocateButton from './LocateButton';
import LockButton from './LockButton';
import MapSettingsDrawer from './MapSettingsDrawer';
import MeasureButton from './MeasureDistanceButton';
import MeasureDistanceInfo from './MeasureDistanceInfo';
import MenuButton from './MenuButton';
import ResetButton from './ResetButton';
import RouteInfo from './RouteInfo';
import RoutesButton from './RoutesButton';
import RoutesDrawer from './RoutesDrawer';
import SearchButton from './SearchButton';
import StationsButton from './StationsButton';
import StationsDrawer from './StationsDrawer';
import StatusMessage from './StatusMessage';
import UndoButton from './UndoButton';

type Props = {
  setRouteLocked: {
    set?: (isLocked: boolean) => void;
  };
  setCorridor: {
    set?: (widthMeters: number) => void;
  };
  undo: {
    do?: () => void;
  };
  undoActions: MapUndoAction[];
  flyTo: {
    do?: (
      position: LatLng | [number, number][],
      bufferWidthMeters?: number,
      height?: number
    ) => void;
  };
  setHeading: {
    set?: (headingDegrees: number) => void;
  };
};

export default function Controls({
  setCorridor,
  undo,
  undoActions,
  flyTo,
  setRouteLocked,
  setHeading,
}: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const setGlobalAircraft = useStore((store) => store.setAircraft);
  const globalAircraft = useStore((store) => store.aircraft);
  const mapSettings = useStore((store) => store.mapSettings);
  const setMapSettings = useStore((store) => store.setMapSettings);
  const [isAdsbDrawerOpen, setIsAdsbDrawerOpen] = useState<boolean>(false);
  const [isRoutesDrawerOpen, setIsRoutesDrawerOpen] = useState<boolean>(false);
  const [isStationsDrawerOpen, setIsStationsDrawerOpen] =
    useState<boolean>(false);

  const modalStation = useStore((store) => store.modalStation);
  const setModalStation = useStore((store) => store.setModalStation);
  const routeState = useStore((store) => store.routeState);
  const setRouteState = useStore((store) => store.setRouteState);
  const setUserSavedRoutes = useStore((store) => store.setUserSavedRoutes);
  const setStatusMessage = useStore((store) => store.setStatusMessage);
  const measureDistanceState = useStore((store) => store.measureDistanceState);
  const setMeasureDistanceState = useStore(
    (store) => store.setMeasureDistanceState
  );
  const userLocation = useStore((store) => store.userLocation);

  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  useEffect(() => {
    const adsbInterval = setInterval(async () => {
      const aircraft = await getAircraft();
      for (const a of aircraft) {
        const globalInstance = globalAircraft.find((ga) => ga.hex === a.hex);
        if (globalInstance) {
          a.prevLats = [globalInstance.lat, ...(globalInstance.prevLats || [])];
          a.prevLons = [globalInstance.lon, ...(globalInstance.prevLons || [])];
          a.prevAlts = [
            globalInstance.altitude,
            ...(globalInstance.prevAlts || []),
          ];
        }
      }
      setGlobalAircraft(aircraft);
    }, 5e3);
    return () => {
      clearInterval(adsbInterval);
    };
  });

  const onPlaneButtonClick = useCallback(async () => {
    setIsAdsbDrawerOpen(true);
  }, []);

  const onStationsButtonClick = useCallback(async () => {
    setIsStationsDrawerOpen(true);
  }, []);

  const onCorridorSliderChange = useCallback(
    (corridorWidthMeters: number) => {
      if (setCorridor.set) {
        setCorridor.set(corridorWidthMeters);
      }
    },
    [setCorridor]
  );

  const onLockButtonClick = useCallback(() => {
    const isLocked = routeState.locked ? false : true;
    if (setRouteLocked.set) {
      setRouteLocked.set(isLocked);
    }
    setRouteState({ ...routeState, locked: isLocked });
  }, [setRouteLocked, routeState, setRouteState]);

  const flyToSearchResult = useCallback(
    (result: LatLng) => {
      if (flyTo.do) {
        flyTo.do(result);
      }
    },
    [flyTo]
  );

  const selectStation = useCallback(
    (s: UserStation) => {
      if (flyTo.do) {
        flyTo.do({
          latitude: s.latitude,
          longitude: s.longitude,
        });
      }
      setModalStation({
        id: s.stationId,
        type: s.stationType,
      });
    },
    [flyTo, setModalStation]
  );

  const onResetButtonClick = useCallback(() => {
    setRouteState({
      ...routeState,
      route: { path: [], corridorWidthMeters: milesToMeters(35) },
    });
  }, [routeState, setRouteState]);

  const onSaveButtonClick = useCallback(async () => {
    try {
      setStatusMessage('Saving route...');
      await saveUserRoute({
        routeName: routeState.name as string,
        path: routeState.route.path.map((p) => ({
          longitude: p[0],
          latitude: p[1],
        })),
        corridorWidthMeters: Math.round(routeState.route.corridorWidthMeters),
      });
      setStatusMessage('Route saved successfully');
      setUserSavedRoutes([]);
    } catch (err) {
      console.error('error saving route', err);
    } finally {
      setTimeout(() => setStatusMessage(undefined), 2e3);
    }
  }, [routeState, setStatusMessage, setUserSavedRoutes]);

  const onCancelButtonClick = useCallback(() => {
    setRouteState({
      editing: false,
      name: undefined,
      locked: false,
      isNewRoute: false,
      route: {
        path: [],
        corridorWidthMeters: milesToMeters(35),
      },
    });
  }, [setRouteState]);

  const selectAircraft = useCallback(
    (a: Aircraft) => {
      if (flyTo.do) {
        flyTo.do(
          {
            latitude: a.lat,
            longitude: a.lon,
          },
          undefined,
          a.altitude + 7000
        );
      }
    },
    [flyTo]
  );

  const onSelectRoute = useCallback(
    (r: Route) => {
      setRouteState({
        route: {
          path: r.path,
          corridorWidthMeters: r.corridorWidthMeters,
        },
        name: r.routeName,
        editing: true,
        locked: true,
        isNewRoute: false,
      });
      if (flyTo.do) {
        flyTo.do(r.path, r.corridorWidthMeters);
      }
    },
    [flyTo, setRouteState]
  );

  const onMeasureDistanceClose = useCallback(() => {
    setMeasureDistanceState({
      editing: false,
      points: [],
      totalDistance: 0,
    });
  }, [setMeasureDistanceState]);

  const onMeasureDistanceButtonClick = useCallback(() => {
    setMeasureDistanceState({
      editing: true,
      points: [],
      totalDistance: 0,
    });
  }, [setMeasureDistanceState]);

  const onCompassClick = useCallback(() => {
    if (setHeading.set) {
      setHeading.set(0);
    }
  }, [setHeading]);

  const onLocateClick = useCallback(() => {
    if (flyTo.do && userLocation) {
      flyTo.do(
        {
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
        },
        undefined,
        2e5
      );
    }
  }, [flyTo, userLocation]);

  const closeStationModal = useCallback(() => {
    setModalStation(undefined);
  }, [setModalStation]);

  return (
    <>
      <div className={styles.leftControls}>
        <RoutesButton onClick={() => setIsRoutesDrawerOpen(true)} />
        <StationsButton onClick={onStationsButtonClick} />
        <AdsbButton onClick={onPlaneButtonClick} />
        <MeasureButton onClick={onMeasureDistanceButtonClick} />
      </div>

      <div className={styles.topControls}>
        <SearchButton onResultClick={flyToSearchResult} />
        <MenuButton onClick={openMenu} />
      </div>

      {routeState.name !== undefined && (
        <>
          <RouteInfo routeName={routeState.name} />
          <div className={styles.routeControls}>
            {routeState.route.path.length > 1 && (
              <Button
                icon={<IconCheckmarkCircleOutline />}
                text="Save"
                onClick={onSaveButtonClick}
              />
            )}
            {undoActions.length > 0 && !routeState.locked && (
              <UndoButton onClick={undo.do ? undo.do : () => {}} />
            )}
            <LockButton
              isLocked={routeState.locked}
              onClick={onLockButtonClick}
            />
            {!routeState.locked && <ResetButton onClick={onResetButtonClick} />}
            <Button
              icon={<IconClose />}
              text="Close"
              onClick={onCancelButtonClick}
            />
          </div>
          {routeState.route.path.length > 0 && !routeState.locked && (
            <CorriderSlider
              value={metersToMiles(routeState.route.corridorWidthMeters)}
              onChange={onCorridorSliderChange}
            />
          )}
        </>
      )}

      <MapSettingsDrawer
        isOpen={isMenuOpen}
        close={() => setIsMenuOpen(false)}
        mapSettings={mapSettings}
        setMapSettings={setMapSettings}
      />

      <AdsbDrawer
        isOpen={isAdsbDrawerOpen}
        onClose={() => setIsAdsbDrawerOpen(false)}
        onSelect={selectAircraft}
      />
      <StationsDrawer
        isOpen={isStationsDrawerOpen}
        onClose={() => setIsStationsDrawerOpen(false)}
        onSelect={selectStation}
      />
      <RoutesDrawer
        isOpen={isRoutesDrawerOpen}
        onClose={() => setIsRoutesDrawerOpen(false)}
        onSelect={onSelectRoute}
      />

      <StatusMessage />
      {measureDistanceState.editing && (
        <MeasureDistanceInfo
          totalDistance={measureDistanceState.totalDistance}
          onClose={onMeasureDistanceClose}
        />
      )}

      <div className={styles.bottomControls}>
        {userLocation && <LocateButton onClick={onLocateClick} />}
        <Compass onClick={onCompassClick} />
      </div>

      {modalStation && (
        <StationModal
          isVisible={modalStation !== null}
          close={closeStationModal}
          stationId={modalStation?.id}
          stationType={modalStation?.type}
        />
      )}
    </>
  );
}

const styles = {
  topControls: css({
    position: 'absolute',
    top: 10,
    right: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '> div': {
      marginRight: '.25rem',
    },
  }),
  leftControls: css({
    top: 10,
    left: 10,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    '> div': {
      marginBottom: '.25rem',
    },
  }),
  bottomControls: css({
    bottom: 10,
    right: 10,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> div': {
      marginLeft: '.5rem',
      marginBottom: '.75rem',
    },
  }),
  routeControls: css({
    position: 'absolute',
    top: 85,
    right: 5,
    display: 'flex',
    flexDirection: 'row',
    '> div': {
      marginRight: '.25rem',
    },
    '.name-input': {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffffdd',
      borderRadius: 5,
      padding: '.25em .5em',
      input: {
        outline: 'none',
        border: 'none',
      },
    },
  }),
};
