import { useCallback, useEffect, useState } from 'react';
import { memoGetStationDetails } from '../api';
import { StationDetails } from '../api/types';

export default function useStationDetails(
  stationId: number,
  refreshMs?: number
) {
  const [stationDetails, setStationDetails] = useState<StationDetails>();

  const getDetails = useCallback(async () => {
    const s = await memoGetStationDetails(stationId);
    setStationDetails(s);
  }, [stationId]);

  useEffect(() => {
    if (!stationDetails) {
      getDetails();
    }
    if (refreshMs) {
      const intId = setInterval(getDetails, refreshMs);
      return () => {
        clearInterval(intId);
      };
    }
  }, [refreshMs, stationDetails, getDetails]);

  return {
    stationDetails,
  };
}
