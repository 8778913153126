import { ReactNode } from 'react';

type Props = {
  label: string;
  value: string | number | ReactNode;
};

export default function DecodedWeatherRow({ label, value }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        fontSize: '1.15em',
      }}
    >
      <div
        style={{
          width: '45%',
          textAlign: 'right',
          verticalAlign: 'middle',
          paddingRight: 10,
          fontWeight: 600,
        }}
      >
        {label}
      </div>
      <div
        style={{
          width: '50%',
          textAlign: 'left',
          verticalAlign: 'middle',
          paddingLeft: 10,
        }}
      >
        {value}
      </div>
    </div>
  );
}
