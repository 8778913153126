import { MwosDetails, StationDetails } from '../../../api/types';
import { FlightCategory } from '../../../types/types';
import DecodedWeather from './DecodedWeather';
import MwosCharts from './MwosCharts';
import MwosDecodedWeather from './MwosDecodedWeather';
import WeatherString from './WeatherString';

type Props = {
  station: StationDetails | MwosDetails;
  isMwos: boolean;
};

export default function WeatherTab({ station, isMwos }: Props) {
  if (isMwos) {
    const m = station as MwosDetails;
    return (
      <>
        <WeatherString rawText={m.observations[0].rawText} />
        <MwosDecodedWeather observation={m.observations[0]} />
        <MwosCharts station={m} />
      </>
    );
  }

  const s = station as StationDetails;
  if (!s.metar) {
    return;
  }
  return (
    <>
      <WeatherString
        rawText={s.metar.rawText}
        flightCategory={s.metar.flightCategory as FlightCategory}
      />
      <DecodedWeather observation={s.metar} />
    </>
  );
}
