import { css } from '@emotion/css';
import { useMemo } from 'react';
import { colors } from '../../../constants/colors';
import { FlightCategory } from '../../../types/types';

type Props = {
  rawText: string;
  flightCategory?: FlightCategory;
};

export default function WeatherString({ rawText, flightCategory }: Props) {
  // const weatherString =
  //   station.observations?.length > 0
  //     ? station.observations[0].rawText
  //     : station.metar?.rawText;

  const wxStringColor = useMemo(() => {
    if (!flightCategory) {
      return '#333';
    }
    switch (flightCategory) {
      case 'IFR':
        return colors.ifr;
      case 'VFR':
        return colors.vfr;
      case 'MVFR':
        return colors.mvfr;
      case 'LIFR':
        return colors.lifr;
    }
  }, [flightCategory]);

  return (
    <div className={styles} style={{ color: wxStringColor }}>
      {rawText}
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffee',
  padding: '0 .75em',
  paddingBottom: '.5em',
  fontSize: '1.45em',
  fontWeight: '600',
  '@media (max-width: 600px)': {
    fontSize: '.75em',
  },
});
