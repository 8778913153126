import { useState } from 'react';
import useStationDetails from '../../../hooks/useStationDetails';
import { StationModalTabName } from '../../../types/types';
import IconClose from '../../svg-icons/Close';
import IconStar from '../../svg-icons/Star';
import IconStarOutline from '../../svg-icons/StarOutline';
import Tabs from './Tabs';
import WeatherTab from './WeatherTab';

type Props = {
  stationId: number;
  isSaved: boolean;
  close: () => void;
  save: () => void;
  unsave: () => void;
};

export default function WeatherStationModal({
  stationId,
  isSaved,
  save,
  unsave,
  close,
}: Props) {
  const [currentTab, setCurrentTab] = useState<StationModalTabName>('weather');
  const { stationDetails: details } = useStationDetails(stationId, 30e3);

  if (!details) {
    return;
  }

  const title = `${details.siteName} (${details.icaoId})`;
  const onTabClick = (tabName: StationModalTabName) => setCurrentTab(tabName);

  return (
    <div className="modal">
      <div className="header">
        <div className="title">
          {isSaved ? (
            <IconStar className="save-button" onClick={unsave} />
          ) : (
            <IconStarOutline className="save-button" onClick={save} />
          )}
          {title}
        </div>
        <div className="controls">
          <IconClose
            className="close-button"
            onClick={() => {
              close();
            }}
          />
        </div>
      </div>
      <Tabs
        currentTab={currentTab}
        onClick={onTabClick}
        isMwos={false}
        hasWeather={details?.metar !== undefined}
      />
      {currentTab === 'weather' && (
        <WeatherTab station={details} isMwos={false} />
      )}
    </div>
  );
}
