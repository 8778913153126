import { css } from '@emotion/css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useCallback, useState } from 'react';
import { colors } from '../../../constants/colors';
import { milesToMeters } from '../../../utils/map';

type Props = {
  value: number;
  onChange: (n: number) => void;
};

export default function CorriderSlider({ value, onChange }: Props) {
  const [indicatorVisible, setIndicatorVisible] = useState<boolean>(false);
  const [val, setVal] = useState<number>(value);
  const onChangeComplete = useCallback(
    (corridorWidthMiles: number | number[]) => {
      const meters = milesToMeters(corridorWidthMiles as number);
      onChange(meters);
      setIndicatorVisible(false);
    },
    [onChange]
  );
  const onChangeCb = useCallback((corridorWidthMiles: number | number[]) => {
    setVal(corridorWidthMiles as number);
    setIndicatorVisible(true);
  }, []);

  return (
    <div className={style}>
      {indicatorVisible && (
        <div className="indicator" style={{ top: 300 - val * 3.15 }}>
          {val} mi
        </div>
      )}

      <Slider
        defaultValue={value}
        min={5}
        max={100}
        step={5}
        vertical={true}
        styles={{
          rail: { width: 10, height: '100%' },
          track: {
            width: 10,
            backgroundColor: colors.primaryLight,
          },
          handle: {
            width: 30,
            height: 30,
            backgroundColor: 'white',
            opacity: 100,
            left: 0,
            border: 'none',
          },
        }}
        onFocus={() => setIndicatorVisible(true)}
        onChange={onChangeCb}
        onChangeComplete={onChangeComplete}
      />
    </div>
  );
}

const style = css({
  position: 'absolute',
  top: 175,
  right: 5,
  width: 75,
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  animation: 'fadeIn .25s',
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '.indicator': {
    backgroundColor: 'white',
    width: 85,
    padding: '5px 10px',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    right: 70,
    borderRadius: 3,
    fontWeight: 500,
    fontSize: 18,
    animation: 'fadeIn .25s',
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
});
