import {
  Cartesian2,
  Cartesian3,
  Color,
  Entity,
  HeightReference,
  LabelStyle,
  VerticalOrigin,
} from 'cesium';
import { labelScaleByDistance, labelTranslucencyByDistance } from './common';

type Props = {
  label: string;
  latitude: number;
  longitude: number;
  altitude: number;
  color: Color;
};

export default function createAircraftEntity({
  label,
  latitude,
  longitude,
  altitude,
  color,
}: Props): Entity {
  return new Entity({
    position: Cartesian3.fromDegrees(longitude, latitude, altitude + 20),
    ellipsoid: {
      radii: new Cartesian3(25, 25, 25),
      material: color || Color.RED,
      heightReference: HeightReference.RELATIVE_TO_TERRAIN,
    },
    name: label,
    label: {
      text: label,
      font: 'bold 12pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(0, 5),
      heightReference: HeightReference.RELATIVE_TO_TERRAIN,
      disableDepthTestDistance: 1e5,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
  });
}
