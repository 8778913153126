import {
  Cartesian2,
  Cartesian3,
  Color,
  Entity,
  HeightReference,
  LabelStyle,
  VerticalOrigin,
} from 'cesium';
import { Airport } from '../../../../api/types';
import airportTower from '../../../../assets/images/airport-with-tower.svg';
import airport from '../../../../assets/images/airport.svg';
import airportPrivate from '../../../../assets/images/airports-private.svg';
import airportSeaport from '../../../../assets/images/airports-seaport.svg';
import { EntityType } from '../../types';
import {
  billboardScaleByDistance,
  billboardTranslucencyByDistance,
  labelScaleByDistance,
  labelTranslucencyByDistance,
} from './common';

// type Airport = {
//   id: number;
//   designator: string;
//   icao: string;
//   name: string;
//   latitude: number;
//   longitude: number;
//   hasControlTower: boolean;
//   type: 'AH' | 'AP' | 'OTHER';
//   privateUse: boolean;
// };

export default function createAirportEntity(a: Airport): Entity {
  return new Entity({
    position: Cartesian3.fromDegrees(a.longitude, a.latitude, 20),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      image: airportImage(a),
      width: 48,
      height: 48,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: a.name,
    label: {
      text: airportLabel(a),
      font: 'bold 16pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.TOP,
      pixelOffset: new Cartesian2(0, 28),
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      // disableDepthTestDistance: 1e5,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      // distanceDisplayCondition: a.icao.length > 0 ? new DistanceDisplayCondition(1.2e10, 1.2e10) : new DistanceDisplayCondition(1.2e2, 1.2e6),
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      airportDetails: null,
      entityType: EntityType.Airport,
    },
  });
}

const airportImage = (a: Airport) => {
  // console.log('airport', a);
  if (a.type === 'AH') {
    if (a.hasControlTower) {
      return airportTower;
    }
    return airport;
  }
  // if (a.type === 'AH') {
  //   return airportHeliport
  // }
  if (a.type === 'OTHER') {
    return airportSeaport;
  }
  if (a.privateUse) {
    return airportPrivate;
  }
  return airport;
};

const airportLabel = (a: Airport) => {
  // console.log('airport', a)
  let label = '';
  // if (a.name.length > 0) {
  //   label += a.name
  // }
  // if (a.designator.length > 0) {
  //   label += ` (${a.designator})`;
  // }
  // if (a.icao.length > 0) {
  //   label += ` (${a.icao})`;
  // }
  label = a.icao;
  return label;
};
