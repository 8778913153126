import buffer from '@turf/buffer';
import turfDistance from '@turf/distance';
import greatCircle from '@turf/great-circle';
import { lineString } from '@turf/helpers';
import { Feature, Polygon, Position } from 'geojson';
import memo from 'memoize/dist/index';
import { LatLng } from '../types/types';

const memoDistance = memo(turfDistance, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});
const memoGreatCircle = memo(greatCircle, {
  maxAge: 180e3,
  cacheKey: JSON.stringify,
});

export const calcBuffer = memo(
  (path: LatLng[], distanceMeters: number): Feature<Polygon> => {
    if (path.length === 1) {
      path = [
        path[0],
        {
          latitude: path[0].latitude + Math.random() * 0.0001,
          longitude: path[0].longitude + Math.random() * 0.0001,
        },
      ];
    }
    const corridorCoords = path.map((p) => [p.longitude, p.latitude]);

    const greatCircleCoords: Position[] = [];
    let lastPoint = corridorCoords[0];
    corridorCoords.slice(1).forEach((pt) => {
      const d = memoDistance(lastPoint, pt, { units: 'degrees' });
      greatCircleCoords.push(
        ...(memoGreatCircle(lastPoint, pt, {
          npoints: Math.max(1, Math.ceil(d)),
        }).geometry.coordinates as Position[])
      );
      lastPoint = pt;
    });

    const f = lineString(greatCircleCoords);
    const corridorFeature = buffer(f, distanceMeters, {
      units: 'meters',
    });

    return corridorFeature as Feature<Polygon>;
  },
  { maxAge: 180e3, cacheKey: JSON.stringify }
);

export const milesToMeters = (miles: number): number => miles * 1609.344;

export const metersToMiles = (meters: number): number => meters / 1609.344;

export const milesToKm = (miles: number): number => milesToMeters(miles) / 1000;
