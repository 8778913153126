import { Aircraft } from '../types/types';

export const getAircraft = async (): Promise<Aircraft[]> => {
  const aircraft = [];
  try {
    const r = await fetch('https://pano.montiscorp.com/adsb/data.json');
    const a = await r.json();
    aircraft.push(...a);
  } catch (err) {
    console.error('error getting merrill aircraft', err);
  }
  try {
    const r = await fetch('https://pano.montiscorp.com/rampart/adsb/data.json');
    const a = await r.json();
    aircraft.push(...a);
  } catch (err) {
    console.error('error getting rampart aircraft', err);
  }
  return aircraft;
};
