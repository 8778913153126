import { css } from '@emotion/css';
import IconRulerMeasure from '../../svg-icons/RulerMeasure';

type Props = {
  onClick: () => void;
};

export default function MeasureButton({ onClick }: Props) {
  return (
    <div className={styles} onClick={onClick}>
      <IconRulerMeasure width="35px" height="35px" />
      <label>Measure</label>
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffdd',
  width: '65px',
  height: '65px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  paddingTop: 4,
  color: '#333',
  label: {
    fontSize: 14,
  },
  ':hover': {
    opacity: 0.85,
    cursor: 'pointer',
  },
  '@media (max-width: 600px)': {
    width: 50,
    height: 50,
    label: {
      fontSize: 11,
    },
  },
});
