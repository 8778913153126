import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import { updateUserStation } from '../../../api';
import { UserStation } from '../../../api/types';
import stationIcon from '../../../assets/images/station_light.svg';
import { colors } from '../../../constants/colors';
import useStore from '../../../state/state';
import { FlightCategory, StationType } from '../../../types/types';
import Drawer from '../../Drawer';
import IconDrag_vertical from '../../svg-icons/DragVertical';
import IconBookmarkStarFill from '../../svg-icons/Star';
import { getStationIcon } from '../station/functions';

type StationsListProps = {
  stations: UserStation[];
  onSelect: (s: UserStation) => void;
};

type StationProps = {
  station: UserStation;
  onSelect: (s: UserStation) => void;
};

const Station = ({ station, onSelect }: StationProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: station.id,
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition,
      }
    : undefined;

  return (
    <li
      key={station.id}
      onClick={() => {
        onSelect(station);
      }}
      style={style}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <IconDrag_vertical style={{ marginRight: 5 }} />
      <StationIcon
        stationType={station.stationType}
        flightCategory={station.flightCategory}
      />
      <StationLabel station={station} />
    </li>
  );
};

const StationsList = ({ stations, onSelect }: StationsListProps) => {
  const [items, setItems] = useState<number[]>([]);

  useEffect(() => {
    setItems(stations.map((s) => s.id));
  }, [stations]);

  const update = async (items: number[]) => {
    const toUpdate = [];
    for (const i in stations) {
      const s = stations[i];
      if (s.displayOrder !== items.indexOf(s.id)) {
        s.displayOrder = items.indexOf(s.id);
        toUpdate.push(s);
      }
    }
    for (const s of toUpdate) {
      await updateUserStation(s.id, s.displayOrder);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id as number);
        const newIndex = items.indexOf(over.id as number);

        update(arrayMove(items, oldIndex, newIndex));

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis]}
      // onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <ul>
        <SortableContext items={items}>
          {items.map((i) => {
            const s = stations.find((s) => s.id === i);
            if (!s) {
              return;
            }
            return <Station key={s.siteName} station={s} onSelect={onSelect} />;
          })}
        </SortableContext>
      </ul>
    </DndContext>
  );
};

type StationIconProps = {
  stationType: StationType;
  flightCategory: FlightCategory;
};

const StationIcon = ({ stationType, flightCategory }: StationIconProps) => {
  return (
    <img
      src={getStationIcon({ stationType, flightCategory })}
      height={24}
      width={24}
    />
  );
};

const StationLabel = ({ station: s }: { station: UserStation }) => {
  if (s.icaoId) {
    return <label>{`${s.siteName} (${s.icaoId})`}</label>;
  }
  return <label>{s.siteName}</label>;
};

type StationsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (s: UserStation) => void;
};

export default function StationsDrawer({
  isOpen,
  onClose,
  onSelect,
}: StationsDrawerProps) {
  const userSavedStations = useStore((store) => store.userSavedStations);
  const refreshUserSavedStations = useStore(
    (store) => store.refreshUserSavedStations
  );

  useEffect(() => {
    if (userSavedStations === undefined) {
      refreshUserSavedStations();
    }
  }, [refreshUserSavedStations, userSavedStations]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      icon={<img src={stationIcon} style={{ width: '1em' }} />}
      title="Saved Stations"
      screenSide="left"
      className={style}
    >
      <span className="info">
        <IconBookmarkStarFill
          color={colors.primaryLight}
          width="2em"
          height="2.5em"
        />
        You can save stations using the bookmark icon in station windows.
      </span>
      {userSavedStations ? (
        <StationsList stations={userSavedStations} onSelect={onSelect} />
      ) : (
        <span>Loading stations...</span>
      )}
    </Drawer>
  );
}

const style = css({
  '.title': {
    fontSize: '1.5em',
    fontWeight: 600,
  },
  '.info': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    svg: {
      marginRight: '.75em',
    },
    padding: '0.5em 0',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: 10,
    li: {
      fontWeight: 400,
      margin: 0,
      fontSize: '1em',
      marginBottom: 5,
      marginLeft: '-0.25em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '.2em .25em',
      cursor: 'pointer',
      label: {
        cursor: 'pointer',
      },
      ':hover': {
        backgroundColor: '#0000000a',
        color: colors.primaryLight,
      },
      img: {
        marginRight: 10,
        marginTop: 2,
      },
    },
  },
});
