import { css } from '@emotion/css';
import { colors } from '../../../constants/colors';
import { StationModalTabName } from '../../../types/types';

type TabsProps = {
  currentTab: StationModalTabName;
  onClick: (n: StationModalTabName) => void;
  isMwos: boolean;
  hasWeather: boolean;
};

export default function Tabs({
  currentTab,
  onClick,
  isMwos,
  hasWeather,
}: TabsProps) {
  return (
    <nav className={styles}>
      <ul>
        <li
          className={currentTab === 'overview' ? 'active' : undefined}
          onClick={() => onClick('overview')}
        >
          <label>Overview</label>
        </li>
        {hasWeather && (
          <li
            className={currentTab === 'weather' ? 'active' : undefined}
            onClick={() => onClick('weather')}
          >
            <label>Weather</label>
          </li>
        )}
        {isMwos && (
          <li
            className={currentTab === 'panorama' ? 'active' : undefined}
            onClick={() => onClick('panorama')}
          >
            <label>Panorama</label>
          </li>
        )}
      </ul>
    </nav>
  );
}

const styles = css({
  backgroundColor: '#ffffffee',
  paddingBottom: '1em',
  ul: {
    borderBottom: '1px solid #aaa',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: '0 .75em',
    li: {
      paddingBottom: '.35em',
      label: {
        display: 'block',
        padding: '.25em .5em',
        fontSize: '1.3em',
        fontWeight: 500,
        color: '#333',
        cursor: 'pointer',
        borderRadius: 5,
        ':hover': {
          color: '#111',
          backgroundColor: '#0000000d',
        },
        '@media (max-width: 600px)': {
          fontSize: '1em',
        },
      },
      marginRight: '1em',
      '&.active': {
        borderBottom: `4px solid ${colors.primaryLight}`,
        label: {
          color: '#111',
          fontWeight: 600,
        },
      },
    },
  },
});
