import bbox from '@turf/bbox';
import centerOfMass from '@turf/center-of-mass';
import distance from '@turf/distance';
import { lineString, point } from '@turf/helpers';
import { Cartesian3, Rectangle, Viewer } from 'cesium';
import {
  memoGetAirportsInBounds,
  memoGetMwosDetails,
  memoGetMwosInBounds,
  memoGetSitesInBounds,
  memoGetStationsInBounds,
} from '../../api';
import {
  Airport,
  MwosDetails,
  Route,
  SiteDetails,
  StationDetails,
} from '../../api/types';
import { LatLng, MapUndoAction, MapUndoActionType } from '../../types/types';
import { calcBuffer } from '../../utils/map';
import { screenSpaceCoordToDeg } from './cesium/entities';

export const executeUndoAction = (
  undoActions: MapUndoAction[],
  route: Route,
  setRoute: (r: Route) => void
): MapUndoAction[] => {
  const undos = undoActions;
  const last = undos.pop();
  if (last?.type === MapUndoActionType.MoveRouteMidpoint) {
    setRoute({
      ...route,
      path: route.path.filter((point) => point !== last.next),
    });
  } else if (last?.type === MapUndoActionType.MoveRoutePoint) {
    const lastPoint = last.next as [number, number];
    setRoute({
      ...route,
      path: route.path.map((point) => {
        console.log('point', point, lastPoint);
        if (point[0] === lastPoint[0] && point[1] === lastPoint[1]) {
          console.log('here', point, last);
          return last.prev as [number, number];
        }
        return point;
      }),
    });
  } else if (last?.type === MapUndoActionType.ChangeCorridorWidth) {
    setRoute({
      ...route,
      corridorWidthMeters: last.prev as number,
    });
  } else if (last?.type === MapUndoActionType.AddRoutePoint) {
    if (route.path.length === 1) {
      setRoute({
        ...route,
        path: [],
      });
    } else {
      setRoute({
        ...route,
        path: route.path.slice(0, route.path.length - 1),
      });
    }
  }
  return undoActions;
};

export const getScreenSites = async (
  pos: LatLng,
  dist: number
): Promise<SiteDetails[]> => {
  const sites = await memoGetSitesInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist * 2.5
  );

  if (!Array.isArray(sites)) {
    return [];
  }
  const sitesWithDetails: SiteDetails[] = [];

  sites
    .filter((s) => s.icao !== 'PAMR')
    .forEach((site) => {
      sitesWithDetails.push({
        ...site,
        stationType: 'faa_camera_site',
      });
    });

  return sitesWithDetails;
};

export const getScreenStations = async (
  pos: LatLng,
  dist: number
): Promise<StationDetails[]> => {
  const stations = await memoGetStationsInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist * 2.5
  );
  if (!Array.isArray(stations)) {
    return [];
  }
  const withDetails: StationDetails[] = [];
  stations.forEach(async (s) => {
    withDetails.push({
      ...s,
      stationType: 'weather_station',
    });
  });

  return withDetails;
};

export const getScreenAirports = async (
  pos: LatLng,
  dist: number
): Promise<Airport[]> => {
  const airports = await memoGetAirportsInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist
  );
  if (!Array.isArray(airports)) {
    return [];
  }
  return airports.filter((a) => a.icao?.length);
};

export const getScreenMwos = async (
  pos: LatLng,
  dist: number
): Promise<MwosDetails[]> => {
  const mwos = await memoGetMwosInBounds(
    pos.latitude * 57.295,
    pos.longitude * 57.295,
    dist
  );
  if (!Array.isArray(mwos)) {
    return [];
  }
  const withDetails: MwosDetails[] = [];
  await Promise.all(
    mwos.map(async (m) => {
      const details = await memoGetMwosDetails(m.id);
      withDetails.push({
        ...details,
        stationType: 'mwos',
      });
    })
  );
  return withDetails;
};

export const rectangleFromRoute = (
  path: [number, number][],
  bufferWidthMeters: number
) => {
  const buf = calcBuffer(
    path.map((p) => ({ latitude: p[1], longitude: p[0] })),
    bufferWidthMeters
  );
  const box = bbox(buf);
  return Rectangle.fromDegrees(box[0], box[1], box[2], box[3]);
};

export const centerOnRoute = (
  viewer: Viewer,
  path: [number, number][],
  bufferWidthMeters: number
) => {
  const routeCenterOfMass = centerOfMass(lineString(path));
  const center = routeCenterOfMass.geometry.coordinates;

  const camPos = screenSpaceCoordToDeg(
    viewer.scene,
    new Cartesian3(
      viewer.canvas.clientWidth / 2,
      viewer.canvas.clientHeight / 2
    )
  );
  const dist = distance(
    point([camPos?.longitude, camPos.latitude]),
    point(center),
    { units: 'miles' }
  );
  let duration = 0;
  if (dist < 100) {
    duration = 1;
  }
  const rect = rectangleFromRoute(path, bufferWidthMeters);
  viewer.camera.flyTo({
    destination: rect,
    duration,
  });
};

// Fly the route!
//
// if (mapState.route.path.length > 10) {
//   const points = [
//     ...mapState.route.path.map((p) => [p.longitude, p.latitude]),
//   ];
//   // console.log('points', points);
//   const ls = lineString(points);
//   // console.log('lineString', lineString);
//   // console.log('along', along(ls, 5, { units: 'miles' }));
//   let totalDistance = 0;
//   for (let i = 0; i < lineString.length; i += 1) {
//     // console.log(ls[i]);
//     totalDistance += distance(
//       point(ls.geometry.coordinates[0]),
//       point(ls.geometry.coordinates[1])
//     );
//   }
//   let dist = 0;
//   const next = () => {
//     // console.log({ dist, totalDistance });
//     if (dist >= totalDistance - 5) {
//       return;
//     }
//     const pt = along(ls, dist, { units: 'miles' });
//     const e = createAircraftEntity({
//       label: '',
//       longitude: pt.geometry.coordinates[0],
//       latitude: pt.geometry.coordinates[1],
//       altitude: 5000,
//       color: Color.TRANSPARENT,
//     });
//     viewer.entities.add(e);

//     const lookAtPoint = along(ls, dist + 1, { units: 'miles' });

//     const camera = viewer.camera;
//     const cameraPosition = viewer.camera.position.clone();
//     let direction = Cartesian3.subtract(
//       Cartesian3.fromDegrees(
//         lookAtPoint.geometry.coordinates[0],
//         lookAtPoint.geometry.coordinates[1],
//         100
//       ),
//       cameraPosition,
//       new Cartesian3()
//     );
//     direction = Cartesian3.normalize(direction, direction);
//     viewer.camera.direction = direction;

//     // get an "approximate" up vector, which in this case we want to be something like the geodetic surface normal.
//     const approxUp = Cartesian3.normalize(cameraPosition, new Cartesian3());

//     // cross viewdir with approxUp to get a right normal
//     let right = Cartesian3.cross(direction, approxUp, new Cartesian3());
//     right = Cartesian3.normalize(right, right);
//     viewer.camera.right = right;

//     // cross right with view dir to get an orthonormal up
//     let up = Cartesian3.cross(right, direction, new Cartesian3());
//     up = Cartesian3.normalize(up, up);
//     camera.up = up;

//     viewer.camera.flyTo({
//       destination: Cartesian3.fromDegrees(
//         pt.geometry.coordinates[0],
//         pt.geometry.coordinates[1],
//         1e3
//       ),
//       orientation: {
//         direction,
//         up,
//       },
//       duration: 1,
//       complete: () => {
//         dist += 5;
//         if (dist < totalDistance - 5) {
//           next();
//         }
//       },
//       easingFunction: EasingFunction.LINEAR_NONE,
//     });

//     // dist += 5;
//   };
//   next();
//   console.log('totalDistance', totalDistance);
// }
