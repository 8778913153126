import centerOfMass from '@turf/center-of-mass';
import distance from '@turf/distance';
import { lineString, point } from '@turf/helpers';
import {
  Cartesian2,
  Cartesian3,
  Cartographic,
  Color,
  createGooglePhotorealistic3DTileset,
  CustomDataSource,
  Ellipsoid,
  Entity,
  HeadingPitchRoll,
  Ion,
  RequestScheduler,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Terrain,
  Viewer,
} from 'cesium';
import { Route, SiteDetails } from '../../../api/types';
import {
  MapCoordinates,
  MeasureDistanceState,
  RouteState,
} from '../../../state/state';
import {
  LatLng,
  MapSettings,
  MapUndoAction,
  MapUndoActionType,
} from '../../../types/types';
import {
  centerOnRoute,
  executeUndoAction,
  getScreenAirports,
  getScreenMwos,
  getScreenSites,
  getScreenStations,
} from '../map-functions';
import { Cesium, EntityType, MapState } from '../types';
import { attachDragHandler } from './drag-handler';
import {
  entitiesWithFlightCategory,
  getEntityType,
  pickResultAsEntity,
  screenSpaceCoordToDeg,
} from './entities';
import createAircraftEntity from './entities/aircraft';
import createAircraftTrackEntity from './entities/aircraft-track';
import createAirportEntity from './entities/airport';
import createMwosEntity from './entities/mwos';
import createRouteCorridorEntity from './entities/route-corridor';
import createRouteLineEntity from './entities/route-line';
import createRoutePointEntity from './entities/route-point';
import createStationEntity from './entities/station';
import { addMeasureDistancePoint } from './measure-distance';

type Props = {
  container: HTMLDivElement;
  initialPosition: LatLng;
  onEntitySelected: (e: Entity) => void;
  setRouteState: (s: RouteState) => void;
  setUndoActions: (a: MapUndoAction[]) => void;
  mapSettings: MapSettings;
  routeState: RouteState;
  setHeading: (h: number) => void;
  measureDistanceState: MeasureDistanceState;
  setMeasureDistanceState: (s: MeasureDistanceState) => void;
  useGoogleTiles: boolean;
  setLastUserCoordinates: (c: MapCoordinates) => void;
};

export default function initCesium({
  container,
  initialPosition,
  onEntitySelected,
  setRouteState,
  setUndoActions,
  mapSettings,
  setHeading,
  routeState,
  measureDistanceState,
  setMeasureDistanceState,
  useGoogleTiles,
  setLastUserCoordinates,
}: Props): Cesium {
  Ion.defaultAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI5MmU4YjFlMS1iZGM1LTQxZDktYjk0MS1lZWFkYjQwOGUxYTQiLCJpZCI6ODY0MSwiaWF0IjoxNzIyNjM0MzIxfQ.72B8HNc_7WdCogDSnS7ztJmWJy1jLsz2y5DF-jqVCIQ';

  RequestScheduler.requestsByServer['tile.googleapis.com:443'] = 18;

  const viewer = new Viewer(container, {
    // terrain: useGoogleTiles ? undefined : Terrain.fromWorldTerrain(),
    // globe: useGoogleTiles ? false : undefined,
    // globe
    // terrain: Terrain.fromWorldTerrain(),
    // skyBox: SkyBox.createEarthSkyBox(),
    navigationHelpButton: false,
    sceneModePicker: false,
    infoBox: false,
    homeButton: false,
    baseLayerPicker: false,
    animation: false,
    fullscreenButton: false,
    timeline: false,
    geocoder: false,
    vrButton: false,
    // requestRenderMode: true,
  });
  console.log('window.devicePixelRatio', window.devicePixelRatio);
  // viewer.resolutionScale = window.devicePixelRatio;
  if (window.outerWidth < 800) {
    viewer.useBrowserRecommendedResolution = false;
    viewer.resolutionScale = 0.35;
  } else {
    viewer.useBrowserRecommendedResolution = false;
    viewer.resolutionScale = 1;
  }
  viewer.scene.postProcessStages.fxaa.enabled = true;
  viewer.scene.msaaSamples = 4;

  if (!useGoogleTiles) {
    viewer.scene.setTerrain(
      Terrain.fromWorldTerrain({
        requestVertexNormals: true,
        requestWaterMask: false,
      })
    );
    viewer.scene.globe.enableLighting = true;
  }

  const init3DTileset = async () => {
    try {
      const tileset = await createGooglePhotorealistic3DTileset(undefined, {
        maximumScreenSpaceError: 48,
      });
      viewer.scene.primitives.add(tileset);
    } catch (error) {
      console.error('failed to load tileset', error);
    }
  };
  if (useGoogleTiles) {
    init3DTileset();
  }

  if (initialPosition) {
    viewer.camera.setView({
      destination: Cartesian3.fromDegrees(
        initialPosition.longitude,
        initialPosition.latitude - 0.75,
        5e4
      ),
      orientation: HeadingPitchRoll.fromDegrees(0, -35, 0),
    });
  }

  const mapState: MapState = {
    routeState: { ...routeState },
    stations: [],
    airports: [],
    mwos: [],
    undoActions: [],
    aircraftEntities: new CustomDataSource('aircraftEntities'),
    mapSettings,
    routeEntities: new CustomDataSource('routeEntities'),
    measureDistanceState: { ...measureDistanceState },
    measureDistanceEntities: new CustomDataSource('measureDistanceEntities'),
  };
  viewer.dataSources.add(mapState.routeEntities);
  viewer.dataSources.add(mapState.measureDistanceEntities);
  viewer.dataSources.add(mapState.aircraftEntities);

  attachDragHandler({
    viewer,
    routeEntities: mapState.routeEntities.entities,
    getRouteLocked: () => mapState.routeState.locked,
    getRoutePath: () => mapState.routeState.route.path,
    updateRoutePoint: (oldPos, newPos) => {
      const point = mapState.routeState.route.path.find(
        (p) => p[0] === oldPos[0] && p[0] === oldPos[0]
      );
      if (!point) {
        console.error(
          'could not find matching route point to update after dragging'
        );
        return;
      }
      point[0] = newPos[0];
      point[1] = newPos[1];
      mapState.undoActions.push({
        type: MapUndoActionType.MoveRoutePoint,
        prev: oldPos,
        next: newPos,
      });
      setUndoActions(mapState.undoActions);
      redrawRouteEntities();
    },
  });

  const handler = new ScreenSpaceEventHandler(viewer.canvas);
  handler.setInputAction((event: ScreenSpaceEventHandler.PositionedEvent) => {
    const result = viewer.scene.pick(event.position);
    const entity = pickResultAsEntity(result);

    const isRouteEntity =
      entity && mapState.routeEntities.entities.contains(entity);
    const isRouteCorridor =
      isRouteEntity && getEntityType(entity) === EntityType.RouteCorridor;
    const isAirport =
      entity && screenEntities.airports.entities.contains(entity);

    if (isRouteCorridor || isAirport) {
      viewer.selectedEntity = undefined;
    } else if (entity) {
      return;
    }

    if (mapState.measureDistanceState.editing === true) {
      const { totalDistance } = addMeasureDistancePoint(
        viewer,
        event.position,
        mapState.measureDistanceEntities.entities,
        mapState.measureDistanceState
      );
      setMeasureDistanceState({
        ...mapState.measureDistanceState,
        totalDistance,
      });
      return;
    }

    if (mapState.routeState.editing === false || mapState.routeState.locked) {
      return;
    }

    const degPos = screenSpaceCoordToDeg(viewer.scene, event.position);

    const newRoutePoint = createRoutePointEntity(degPos, false);
    mapState.routeEntities.entities.add(newRoutePoint);

    mapState.undoActions.push({
      type: MapUndoActionType.AddRoutePoint,
    });
    setUndoActions(mapState.undoActions);

    // const z = newRoutePoint.position?.getValue(
    //   JulianDate.fromDate(new Date())
    // )?.z;
    mapState.routeState.route.path.push([degPos.longitude, degPos.latitude]);

    redrawRouteEntities();
    setRouteState(mapState.routeState);
  }, ScreenSpaceEventType.LEFT_CLICK);

  const redrawRouteEntities = () => {
    mapState.routeEntities.entities.removeAll();
    if (mapState.routeState.route.path.length === 0) {
      return;
    }
    const re = createRouteLineEntity(mapState.routeState.route.path);
    mapState.routeEntities.entities.add(re);

    for (const p of mapState.routeState.route.path) {
      const e = createRoutePointEntity(
        { longitude: p[0], latitude: p[1] },
        mapState.routeState.locked
      );
      mapState.routeEntities.entities.add(e);
    }

    const ce = createRouteCorridorEntity(
      mapState.routeState.route.path,
      mapState.routeState.route.corridorWidthMeters
    );
    mapState.routeEntities.entities.add(ce);

    measureRoute();
  };

  const routeMeasurementEntities: Entity[] = [];
  const measureRoute = () => {
    for (const e of routeMeasurementEntities) {
      viewer.entities.remove(e);
    }
    for (let i = 0; i < mapState.routeState.route.path.length - 1; i += 1) {
      const p1 = mapState.routeState.route.path[i];
      const p2 = mapState.routeState.route.path[i + 1];

      const dist = distance(point(p1), point(p2));
      const center = centerOfMass(lineString([p1, p2]));

      const e = createAircraftEntity({
        label: `${dist.toFixed(2)} mi`,
        longitude: center.geometry.coordinates[0],
        latitude: center.geometry.coordinates[1],
        altitude: 100,
        color: Color.TRANSPARENT,
      });
      viewer.entities.add(e);
      routeMeasurementEntities.push(e);
    }
  };

  viewer.selectedEntityChanged.addEventListener(onEntitySelected);

  const screenEntities = {
    airports: new CustomDataSource('screenEntities.airports'),
    stations: new CustomDataSource('screenEntities.stations'),
    cameraSites: new CustomDataSource('screenEntities.cameraSites'),
    mwos: new CustomDataSource('screenEntities.mwos'),
  };
  viewer.dataSources.add(screenEntities.airports);
  viewer.dataSources.add(screenEntities.stations);
  viewer.dataSources.add(screenEntities.cameraSites);
  viewer.dataSources.add(screenEntities.mwos);

  screenEntities.airports.show = mapSettings.showMarkers.airports;
  screenEntities.stations.show = mapSettings.showMarkers.weatherStations;
  screenEntities.cameraSites.show = mapSettings.showMarkers.cameraSites;

  const fetchScreenData = async () => {
    const bounds = viewer.camera.computeViewRectangle(Ellipsoid.WGS84);
    if (!bounds) {
      return;
    }
    if (!bounds.east || !bounds.west || !bounds.north || !bounds.south) {
      return;
    }

    const dist = Math.min(
      2e5,
      distance(
        point([bounds.east * 57.295, bounds.north * 57.295]),
        point([bounds.west * 57.295, bounds.south * 57.295]),
        { units: 'meters' }
      )
    );

    const cartesian = viewer.camera.pickEllipsoid(
      new Cartesian2(
        viewer.canvas.clientWidth / 2,
        viewer.canvas.clientHeight / 2
      ),
      Ellipsoid.WGS84
    ) as Cartesian3;

    if (!cartesian) {
      return;
    }

    const pos = Cartographic.fromCartesian(cartesian, Ellipsoid.WGS84);

    let sites: SiteDetails[] = [];
    if (mapState.mapSettings.showMarkers.cameraSites) {
      sites = await getScreenSites(pos, dist);
      screenEntities.cameraSites.entities.removeAll();
      for (const s of sites) {
        screenEntities.cameraSites.entities.add(createStationEntity(s));
      }
      const mwos = await getScreenMwos(pos, dist);
      screenEntities.mwos.entities.removeAll();
      for (const m of mwos) {
        screenEntities.mwos.entities.add(createMwosEntity(m));
      }
    }
    if (mapState.mapSettings.showMarkers.weatherStations) {
      const stations = await getScreenStations(pos, dist);
      screenEntities.stations.entities.removeAll();
      // Skip stations we're already showing via camera sites
      for (const s of stations) {
        if (sites?.find((site) => site.stationId === s.id)) {
          continue;
        }
        if (
          s.flightCategory === 'VFR' &&
          mapSettings.showMarkers.vfr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === 'MVFR' &&
          mapSettings.showMarkers.mvfr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === 'LIFR' &&
          mapSettings.showMarkers.lifr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === 'IFR' &&
          mapSettings.showMarkers.ifr === false
        ) {
          continue;
        }
        if (
          s.flightCategory === undefined &&
          mapSettings.showMarkers.noFlightCategory === false
        ) {
          continue;
        }

        screenEntities.stations.entities.add(createStationEntity(s));
      }
    }
    if (mapState.mapSettings.showMarkers.airports) {
      const airports = await getScreenAirports(pos, dist);
      screenEntities.airports.entities.removeAll();
      for (const a of airports) {
        screenEntities.airports.entities.add(createAirportEntity(a));
      }
    }
    viewer.scene.requestRender();
  };

  fetchScreenData();

  const onCameraMoveEnd = () => {
    fetchScreenData();

    const cartesian = viewer.camera.pickEllipsoid(
      new Cartesian2(
        viewer.canvas.clientWidth / 2,
        viewer.canvas.clientHeight / 2
      ),
      Ellipsoid.WGS84
    ) as Cartesian3;
    const pos = Cartographic.fromCartesian(cartesian, Ellipsoid.WGS84);
    setLastUserCoordinates({
      latitude: pos.latitude * 57.295,
      longitude: pos.longitude * 57.295,
      heightMeters: pos.height,
    });
  };

  const removeCameraMoveEndListener =
    viewer.camera.moveEnd.addEventListener(onCameraMoveEnd);

  const cameraMoveInterval = setInterval(
    () => setHeading(viewer.camera.heading),
    2e4
  );

  viewer.camera.percentageChanged = 0.01;
  const removeCameraChangedListener = viewer.camera.changed.addEventListener(
    () => setHeading(viewer.camera.heading)
  );

  return {
    dispose: () => {
      removeCameraMoveEndListener();
      removeCameraChangedListener();
      clearInterval(cameraMoveInterval);
      viewer.destroy();
    },
    clearSelectedEntity: () => {
      viewer.selectedEntity = undefined;
    },
    setAircraft: (aircraft) => {
      if (!aircraft) {
        return;
      }
      if (!viewer.cesiumWidget) {
        return;
      }
      const newEntities = [];
      for (const a of aircraft) {
        const e = createAircraftEntity({
          label: a.flight,
          latitude: a.lat,
          longitude: a.lon,
          altitude: a.altitude,
          color: Color.RED,
        });
        newEntities.push(e);

        if (a.prevLats?.length > 0) {
          const points: [number, number, number][] = [
            [a.lon, a.lat, a.altitude],
          ];
          a.prevLats.map((_, i) => {
            points.push([a.prevLons[i], a.prevLats[i], a.prevAlts[i]]);
          });
          const trackEntity = createAircraftTrackEntity({ points });
          newEntities.push(trackEntity);
        }
      }
      mapState.aircraftEntities.entities.removeAll();
      newEntities.map((e) => mapState.aircraftEntities.entities.add(e));
    },
    viewer,
    setCorridor: (corridorWidthMeters: number) => {
      mapState.undoActions.push({
        type: MapUndoActionType.ChangeCorridorWidth,
        prev: mapState.routeState.route.corridorWidthMeters,
        next: corridorWidthMeters,
      });
      mapState.routeState.route.corridorWidthMeters = corridorWidthMeters;
      setUndoActions(mapState.undoActions);
      redrawRouteEntities();
    },
    undo: () => {
      mapState.undoActions = executeUndoAction(
        mapState.undoActions,
        mapState.routeState.route,
        (newRoute: Route) => {
          mapState.routeState.route = newRoute;
          redrawRouteEntities();
          // fetchRouteData();
        }
      );
      if (mapState.undoActions.length === 0) {
        setUndoActions([]);
      } else {
        setUndoActions(mapState.undoActions);
      }
      setRouteState(mapState.routeState);
    },
    flyTo: (
      position: LatLng | [number, number][],
      bufferWidthMeters?: number,
      height?: number
    ) => {
      if (Array.isArray(position)) {
        centerOnRoute(viewer, position, (bufferWidthMeters || 0) / 2);
        return;
      }

      const { latitude, longitude } = position;
      const camPos = screenSpaceCoordToDeg(
        viewer.scene,
        new Cartesian3(
          viewer.canvas.clientWidth / 2,
          viewer.canvas.clientHeight / 2
        )
      );
      const dist = distance(
        point([camPos?.longitude, camPos.latitude]),
        point([longitude, latitude]),
        { units: 'miles' }
      );
      let duration = 0;
      if (dist < 100) {
        duration = 1;
      }
      viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(longitude, latitude, height || 2e4),
        duration,
      });
    },
    setMeasureDistanceState: (s) => {
      if (s.editing === false) {
        mapState.measureDistanceEntities.entities.removeAll();
      }
      mapState.measureDistanceState = s;
    },
    setRoute: (r: Route) => {
      mapState.routeState.route = r;
      mapState.undoActions = [];
      setUndoActions([]);
      redrawRouteEntities();
    },
    setLocked: (l) => {
      mapState.routeState.locked = l;
      redrawRouteEntities();
    },
    setMapSettings: (mapSettings: MapSettings) => {
      mapState.mapSettings = mapSettings;

      screenEntities.airports.show = mapSettings.showMarkers.airports;
      screenEntities.stations.show = mapSettings.showMarkers.weatherStations;
      screenEntities.cameraSites.show = mapSettings.showMarkers.cameraSites;
      screenEntities.mwos.show = mapSettings.showMarkers.cameraSites;

      if (!mapSettings.showMarkers.airports) {
        screenEntities.airports.entities.removeAll();
      }

      if (!mapSettings.showMarkers.weatherStations) {
        screenEntities.stations.entities.removeAll();
      } else {
        const { vfr, mvfr, lifr, ifr, noFlightCategory } =
          mapSettings.showMarkers;
        const es = screenEntities.stations.entities;
        if (!vfr) {
          entitiesWithFlightCategory(es, 'VFR').map((e) => es.removeById(e.id));
        }
        if (!mvfr) {
          entitiesWithFlightCategory(es, 'MVFR').map((e) =>
            es.removeById(e.id)
          );
        }
        if (!lifr) {
          entitiesWithFlightCategory(es, 'LIFR').map((e) =>
            es.removeById(e.id)
          );
        }
        if (!ifr) {
          entitiesWithFlightCategory(es, 'IFR').map((e) => es.removeById(e.id));
        }
        if (!noFlightCategory) {
          entitiesWithFlightCategory(es).map((e) => es.removeById(e.id));
        }
      }

      if (!mapSettings.showMarkers.cameraSites) {
        screenEntities.cameraSites.entities.removeAll();
        screenEntities.mwos.entities.removeAll();
      }

      fetchScreenData();
    },
    setHeading: (headingDeg: number) => {
      viewer.camera.flyTo({
        destination: viewer.camera.positionWC,
        orientation: {
          heading: headingDeg,
          pitch: viewer.camera.pitch,
          roll: viewer.camera.roll,
        },
        duration: 0.5,
      });
    },
    setRouteState: (s: RouteState) => {
      mapState.routeState = { ...s };
      redrawRouteEntities();

      if (mapState.routeState.route.path.length === 0) {
        mapState.undoActions = [];
        setUndoActions([]);
        measureRoute();
      }
    },
  };
}
