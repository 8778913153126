import {
  Cartesian2,
  Cartesian3,
  Color,
  Entity,
  HeightReference,
  LabelStyle,
  VerticalOrigin,
} from 'cesium';
import { MwosDetails } from '../../../../api/types';

import mwosIcon from '../../../../assets/images/mwos.svg';
import { EntityType } from '../../types';
import {
  billboardScaleByDistance,
  billboardTranslucencyByDistance,
  labelPixelOffsetByDistance,
  labelScaleByDistance,
  labelTranslucencyByDistance,
} from './common';

export default function createMwosEntity(mwos: MwosDetails): Entity {
  if (!mwos || !mwos.latitude) {
    console.error('cannot create MWOS entity');
    return new Entity();
  }
  return new Entity({
    position: Cartesian3.fromDegrees(mwos.longitude, mwos.latitude, 30),
    billboard: {
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      image: mwosIcon,
      width: 48,
      height: 48,
      scaleByDistance: billboardScaleByDistance,
      translucencyByDistance: billboardTranslucencyByDistance,
    },
    name: mwos.icaoId,
    label: {
      text: mwos.icaoId,
      font: 'bold 16pt "Open Sans", sans-serif',
      verticalOrigin: VerticalOrigin.BASELINE,
      heightReference: HeightReference.CLAMP_TO_TERRAIN,
      pixelOffset: new Cartesian2(0, 45),
      pixelOffsetScaleByDistance: labelPixelOffsetByDistance,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      scaleByDistance: labelScaleByDistance,
      translucencyByDistance: labelTranslucencyByDistance,
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK,
      outlineWidth: 1,
      style: LabelStyle.FILL_AND_OUTLINE,
    },
    properties: {
      stationDetails: mwos,
      entityType: EntityType.MWOS,
    },
  });
}
