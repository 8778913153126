import { css } from '@emotion/css';
import { DewPoint, Pressure, Temperature, WindSpeed } from '@montiscorp/charts';
import { MwosDetails } from '../../../api/types';

type Props = {
  station: MwosDetails;
};

export default function MwosCharts({ station }: Props) {
  if (!station.observations) {
    return;
  }
  const temp = station.observations.map((o) => ({
    time: new Date(o.observationTime),
    value: o.tempC,
  }));
  const dewpoint = station.observations.map((o) => ({
    time: new Date(o.observationTime),
    value: o.dewpointC,
  }));

  const pressure = station.observations.map((o) => ({
    time: new Date(o.observationTime),
    value: o.pressureHpa,
  }));

  const windSpeed = station.observations.map((o) => ({
    time: new Date(o.observationTime),
    value: o.windSpeedKt,
  }));

  return (
    <div className={styles}>
      <div>
        <label>Temperature</label>
        <Temperature data={temp} height={250} />
      </div>
      <div>
        <label>Pressure</label>
        <Pressure data={pressure} height={250} />
      </div>
      <div>
        <label>Dew Point</label>
        <DewPoint data={dewpoint} height={250} />
      </div>
      <div>
        <label>Wind Speed</label>
        <WindSpeed data={windSpeed} height={250} />
      </div>
    </div>
  );
}

const styles = css({
  backgroundColor: '#ffffffee',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  '> div': {
    width: '50%',
    label: {
      width: '100%',
      display: 'block',
      textAlign: 'center',
      fontSize: '1.15em',
      fontWeight: 600,
    },
  },
  svg: {
    width: '100%',
    height: 250,
    '[aria-label="text"]': {
      fontSize: 23,
      transform: 'translateY(-42px)',
    },
  },
});
