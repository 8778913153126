import { Camera, MwosCamera } from '../../../api/types';
import { minutesAgo } from './functions';

type Props = {
  camera: Camera | MwosCamera;
  isMwos: boolean;
};

export default function SingleImage({ camera, isMwos }: Props) {
  if (isMwos) {
    const c = camera as MwosCamera;
    return (
      <div className="image-wrapper">
        <img
          src={c.currentImageUrl}
          style={{ width: '100%', padding: 2, backgroundColor: '#ffffffdd' }}
        />
        <span className="sub">
          {c.directionText} ({minutesAgo(c.currentImageObservationTime)} min
          ago)
        </span>
      </div>
    );
  }

  const c = camera as Camera;
  return (
    <div className="image-wrapper">
      <img
        src={camera.currentImageUrl}
        style={{ width: '100%', padding: 2, backgroundColor: '#ffffffdd' }}
      />
      <span className="sub">
        {c.cameraDirection} ({minutesAgo(c.cameraLastSuccess)} min ago)
      </span>
    </div>
  );
}
