export enum UserType {
  Internal,
  Public,
}

export type LatLng = {
  latitude: number;
  longitude: number;
};

export type LatLngAlt = {
  latitude: number;
  longitude: number;
  altitude?: number;
};

export type MarkerType =
  | 'cameraSites'
  | 'airports'
  | 'weatherStations'
  | 'vfr'
  | 'mvfr'
  | 'ifr'
  | 'lifr'
  | 'noFlightCategory'
  | 'aircraft';

export type MapType = 'default' | 'low-bandwidth';

export type MapSettings = {
  mapType: MapType;
  showMarkers: {
    [key in MarkerType]: boolean;
  };
  rememberUserLocation: boolean;
};

export enum MapUndoActionType {
  AddRoutePoint,
  MoveRoutePoint,
  MoveRouteMidpoint,
  ChangeCorridorWidth,
}

export type MapUndoAction = {
  type: MapUndoActionType;
  prev?: unknown;
  next?: unknown;
};

export type MapMarker = {
  title?: string;
  description?: string;
  latlng: LatLng;
  extra?: object;
};

export type MapStationMarker = {
  title: string;
  description: string;
  latlng: LatLng;
  flightCategory?: FlightCategory;
  hasCameraImages: boolean;
  stationId: StationId;
};

export type MapRouteMidpointMarker = {
  latlng: LatLng;
  afterPoint: LatLng;
};

export type MapUserLocationMarker = {
  latlang: LatLng;
  gpsActive: boolean;
};

export type CameraDirection =
  | 'North'
  | 'NorthEast'
  | 'East'
  | 'SouthEast'
  | 'South'
  | 'SouthWest'
  | 'West'
  | 'NorthWest';

export type Camera = {
  id: string;
  direction: CameraDirection;
  currentImageUrl: string;
  clearDayImageUrl: string;
  observationDateTime: string;
};

export type StationId = number;

export type FlightCategory = 'VFR' | 'MVFR' | 'IFR' | 'LIFR';

export type Aircraft = {
  hex: string;
  flight: string;
  lat: number;
  lon: number;
  altitude: number;
  track: number;
  speed: number;
  prevLats: number[];
  prevLons: number[];
  prevAlts: number[];
};

export type StationModalTabName = 'overview' | 'weather' | 'panorama';

export type StationType = 'faa_camera_site' | 'weather_station' | 'mwos';
