import { CustomDataSource, Viewer } from 'cesium';
import { Route, Station } from '../../api/types';
import { MeasureDistanceState, RouteState } from '../../state/state';
import {
  Aircraft,
  LatLng,
  MapSettings,
  MapUndoAction,
} from '../../types/types';

export enum EntityType {
  RoutePoint,
  RouteLine,
  RouteCorridor,
  Airport,
  MWOS,
  CameraSite,
  WeatherStation,
}

export type Cesium = {
  dispose: () => void;
  viewer: Viewer;
  setAircraft: (a: Aircraft[]) => void;
  setCorridor: (corridorWidthMeters: number) => void;
  undo: (u: MapUndoAction) => void;
  flyTo: (
    p: LatLng | [number, number][],
    bufferWidthMeters?: number,
    height?: number
  ) => void;
  setRoute: (r: Route) => void;
  setLocked: (l: boolean) => void;
  setMapSettings: (s: MapSettings) => void;
  setHeading: (h: number) => void;
  setRouteState: (s: RouteState) => void;
  setMeasureDistanceState: (s: MeasureDistanceState) => void;
  clearSelectedEntity: () => void;
};

export type MapState = {
  routeState: RouteState;
  stations: Station[];
  airports: [];
  mwos: [];
  undoActions: MapUndoAction[];
  aircraftEntities: CustomDataSource;
  mapSettings: MapSettings;
  measureDistanceState: MeasureDistanceState;
  routeEntities: CustomDataSource;
  measureDistanceEntities: CustomDataSource;
};
