import { Aircraft } from '../types/types';
import { AircraftFilters } from './state';

export const filterAircraft = (
  aircraft: Aircraft[],
  aircraftFilters: AircraftFilters
): Aircraft[] => {
  const { callsign } = aircraftFilters;
  let filtered = aircraft;
  if (callsign) {
    filtered = filtered.filter((f) =>
      f.flight.toLowerCase().includes(callsign.toLowerCase())
    );
  }
  return filtered;
};
