import { useEffect, useState } from 'react';
import { LatLng } from '../types/types';

export default function useGeolocation() {
  const [location, setLocation] = useState<LatLng>();
  const [error, setError] = useState<string>();

  const refresh = () => {
    setLocation(undefined);
    setError(undefined);
  };

  useEffect(() => {
    if (!navigator.geolocation) {
      setError('Geolocation is not supported');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        setError(error.message);
      }
    );
  }, []);

  return {
    location,
    error,
    refresh,
  };
}
