// icon:drag_vertical | System UIcons https://systemuicons.com/ | Corey Ginnivan
import * as React from 'react';

function IconDrag_vertical(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 21 21"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M7 5h2v2H7zM12 5h2v2h-2zM7 9h2v2H7zM12 9h2v2h-2zM7 13h2v2H7zM12 13h2v2h-2z" />
      </g>
    </svg>
  );
}

export default IconDrag_vertical;
