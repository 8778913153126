import { useAuthenticator } from '@aws-amplify/ui-react';
import { css } from '@emotion/css';
import { useCallback } from 'react';
import { colors } from '../../../constants/colors';
import { MapSettings, MarkerType } from '../../../types/types';
import Drawer from '../../Drawer';
import ToggleSwitch from '../../ToggleSwitch';
import MenuIcon from '../../svg-icons/Menu';

import stationIfrIcon from '../../../assets/images/station_ifr.svg';
import stationLifrIcon from '../../../assets/images/station_lifr.svg';
import stationMvfrIcon from '../../../assets/images/station_mvfr.svg';
import stationPlainIcon from '../../../assets/images/station_plain.svg';
import stationVfrIcon from '../../../assets/images/station_vfr.svg';
import IconHelpCircleOutline from '../../svg-icons/HelpCircleOutline';

type StationTogglesProps = {
  showMarkers: {
    [key in MarkerType]: boolean;
  };
  updateShowMarkers: (k: MarkerType, v: boolean) => void;
};

const StationToggles = ({
  showMarkers,
  updateShowMarkers,
}: StationTogglesProps) => {
  const updateVfr = (value: boolean) => updateShowMarkers('vfr', value);
  const updateMvfr = (value: boolean) => updateShowMarkers('mvfr', value);
  const updateLifr = (value: boolean) => updateShowMarkers('lifr', value);
  const updateIfr = (value: boolean) => updateShowMarkers('ifr', value);
  const updateNoFlightCategory = (value: boolean) =>
    updateShowMarkers('noFlightCategory', value);

  return (
    <>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationVfrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          VFR
        </span>
        <ToggleSwitch value={showMarkers.vfr} onChange={updateVfr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationMvfrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          MVFR
        </span>
        <ToggleSwitch value={showMarkers.mvfr} onChange={updateMvfr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationLifrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          LIFR
        </span>
        <ToggleSwitch value={showMarkers.lifr} onChange={updateLifr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationIfrIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          IFR
        </span>{' '}
        <ToggleSwitch value={showMarkers.ifr} onChange={updateIfr} />
      </li>
      <li style={{ paddingLeft: '1em' }}>
        <span style={{ display: 'flex', flexDirection: 'row' }}>
          <img
            src={stationPlainIcon}
            style={{ width: '1em', marginRight: '.5em' }}
          />
          No flight category
        </span>{' '}
        <ToggleSwitch
          value={showMarkers.noFlightCategory}
          onChange={updateNoFlightCategory}
        />
      </li>
    </>
  );
};

type Props = {
  isOpen: boolean;
  close: () => void;
  mapSettings: MapSettings;
  setMapSettings: (s: MapSettings) => void;
};

export default function MapSettingsDrawer({
  isOpen,
  close,
  mapSettings,
  setMapSettings,
}: Props) {
  const auth = useAuthenticator();

  const signOut = useCallback(() => {
    auth.signOut();
  }, [auth]);

  const updateShowMarkers = (
    key:
      | 'airports'
      | 'cameraSites'
      | 'weatherStations'
      | 'vfr'
      | 'mvfr'
      | 'ifr'
      | 'lifr'
      | 'noFlightCategory'
      | 'aircraft',
    value: boolean
  ) => {
    mapSettings.showMarkers[key] = value;
    setMapSettings({ ...mapSettings });
  };
  const updateAirports = (value: boolean) =>
    updateShowMarkers('airports', value);

  const updateCameraSites = (value: boolean) =>
    updateShowMarkers('cameraSites', value);

  const updateWeatherStations = (value: boolean) =>
    updateShowMarkers('weatherStations', value);

  const updateRememberUserLocation = (value: boolean) =>
    setMapSettings({ ...mapSettings, rememberUserLocation: value });

  if (!isOpen) {
    return false;
  }

  return (
    <Drawer
      screenSide="right"
      isOpen={isOpen}
      onClose={close}
      title="Map Menu"
      className={style}
      icon={<MenuIcon />}
    >
      <div className="section">
        <span className="title">Display Icons</span>
        <ul>
          <li>
            Airports{' '}
            <ToggleSwitch
              value={mapSettings.showMarkers.airports}
              onChange={updateAirports}
            />
          </li>
          <li>
            Camera Sites{' '}
            <ToggleSwitch
              value={mapSettings.showMarkers.cameraSites}
              onChange={updateCameraSites}
            />
          </li>
          <li>
            Weather Stations{' '}
            <ToggleSwitch
              value={mapSettings.showMarkers.weatherStations}
              onChange={updateWeatherStations}
            />
          </li>
          {mapSettings.showMarkers.weatherStations && (
            <StationToggles
              showMarkers={mapSettings.showMarkers}
              updateShowMarkers={updateShowMarkers}
            />
          )}
        </ul>
      </div>

      <div className="section">
        <span className="title">Behavior</span>
        <ul>
          <li>
            <span>
              Remember Location{' '}
              <div className="tooltip">
                <IconHelpCircleOutline />
                <span className="tooltip-text">
                  Open the map to the last location from your previous session
                </span>
              </div>
            </span>
            <ToggleSwitch
              value={mapSettings.rememberUserLocation}
              onChange={updateRememberUserLocation}
            />
          </li>
        </ul>
      </div>

      <button onClick={signOut} className="sign-out">
        Sign Out
      </button>
    </Drawer>
  );
}

const tooltipWidth = 200;

const style = css({
  'button.sign-out': {
    padding: '.5em 1em',
    backgroundColor: colors.primaryLight,
    color: 'white',
    borderRadius: 5,
    // marginRight: 10,
    position: 'absolute',
    right: '1em',
    bottom: '1em',
    fontWeight: 600,
    outline: 'none',
    borderWidth: 0,
    fontSize: '1.25em',
    cursor: 'pointer',
    ':hover': {
      opacity: 0.9,
    },
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginTop: 10,
    li: {
      fontWeight: 400,
      margin: 0,
      padding: 0,
      fontSize: '1.15em',
      marginBottom: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      span: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        svg: {
          marginLeft: 3,
        },
        '.tooltip': {
          position: 'relative',
          display: 'inline-block',
          '.tooltip-text': {
            fontSize: '.9em',
            visibility: 'hidden',
            textAlign: 'center',
            padding: '.5em .65em',
            borderRadius: 5,
            backgroundColor: '#333',
            color: 'white',
            position: 'absolute',
            zIndex: 2,
            width: tooltipWidth,
            left: '50%',
            bottom: '100%',
            marginLeft: -tooltipWidth / 2,
          },
          ':hover .tooltip-text': {
            visibility: 'visible',
          },
        },
      },
    },
  },
  '.section': {
    marginBottom: 20,
    paddingBottom: 20,
    '.title': {
      fontSize: '1.5em',
      fontWeight: 600,
    },
    borderBottom: '1px solid #aaa',
    '@media (max-width: 600px)': {
      fontSize: 13,
    },
  },
  '.section:last-child': {
    borderBottom: 'none',
  },
  '.section.routes, .section.stations': {
    ul: {
      maxHeight: 200,
      overflow: 'scroll',
    },
    li: {
      cursor: 'pointer',
      padding: '2px 0px',
      ':hover': {
        backgroundColor: '#0000000a',
        color: colors.primaryLight,
      },
    },
  },
  '@media (max-width: 600px)': {
    width: 280,
    button: {
      fontSize: 15,
    },
  },
});
