import { Cartesian3, Color, Entity } from 'cesium';
import { calcBuffer } from '../../../../utils/map';
import { EntityType } from '../../types';

export default function createRouteCorridorEntity(
  path: [number, number][],
  widthMeters: number
): Entity {
  const buffer = calcBuffer(
    path.map((p) => ({ longitude: p[0], latitude: p[1] })),
    widthMeters / 2
  );

  return new Entity({
    name: 'Buffer',
    polygon: {
      hierarchy: Cartesian3.fromDegreesArray(
        buffer.geometry.coordinates.flat(2)
      ),
      material: Color.fromCssColorString('#00000044'),
    },
    properties: {
      entityType: EntityType.RouteCorridor,
    },
  });
}
