import { css } from '@emotion/css';
import { useCallback, useState } from 'react';
import {
  Camera,
  MwosCamera,
  MwosDetails,
  SiteDetails,
  StationDetails,
} from '../../../api/types';
import { FlightCategory } from '../../../types/types';
import AllImages from './AllImages';
import CameraTabs from './CameraTabs';
import SingleImage from './SingleImage';
import WeatherString from './WeatherString';

type OverviewTabProps = {
  station: SiteDetails | StationDetails | MwosDetails;
  cameras: Camera[] | MwosCamera[];
  isMwos: boolean;
};

export default function OverviewTab({
  station,
  cameras,
  isMwos,
}: OverviewTabProps) {
  if (isMwos) {
    const m = station as MwosDetails;
    const c = cameras as MwosCamera[];
    return <MwosOverview station={m} cameras={c} />;
  }

  const s = station as StationDetails;
  const c = cameras as Camera[];
  return <FaaCameraSiteOverview station={s} cameras={c} />;
}

type MwosOverviewProps = {
  station: MwosDetails;
  cameras: MwosCamera[];
};

const MwosOverview = ({ station, cameras }: MwosOverviewProps) => {
  const [currentCamera, setCurrentCamera] = useState<MwosCamera | undefined>();

  const onClickImage = useCallback((camera: MwosCamera | Camera) => {
    setCurrentCamera(camera as MwosCamera);
  }, []);
  const onClickTab = useCallback(
    (directionText?: string) => {
      const camera = cameras.find((c) => c.directionText === directionText);
      setCurrentCamera(camera);
    },
    [cameras]
  );

  return (
    <>
      <WeatherString rawText={station.observations[0].rawText} />
      <CameraTabs
        labels={cameras.map((c) => c.directionText)}
        currentTab={currentCamera?.directionText}
        onClickTab={onClickTab}
      />
      <div className={styles}>
        {currentCamera ? (
          <SingleImage camera={currentCamera} isMwos />
        ) : (
          <AllImages cameras={cameras} isMwos onClick={onClickImage} />
        )}
      </div>
    </>
  );
};

type FaaCameraSiteOverviewProps = {
  station: StationDetails;
  cameras: Camera[];
};

const FaaCameraSiteOverview = ({
  station,
  cameras,
}: FaaCameraSiteOverviewProps) => {
  const [currentCamera, setCurrentCamera] = useState<Camera | undefined>();

  const onClickImage = useCallback((camera: Camera | MwosCamera) => {
    setCurrentCamera(camera as Camera);
  }, []);
  const onClickTab = useCallback(
    (cameraDirection?: string) => {
      const camera = cameras.find((c) => c.cameraDirection === cameraDirection);
      setCurrentCamera(camera);
    },
    [cameras]
  );

  return (
    <>
      <WeatherString
        rawText={station.metar?.rawText || ''}
        flightCategory={station.metar?.flightCategory as FlightCategory}
      />
      {cameras && (
        <>
          <CameraTabs
            labels={cameras.map((c) => c.cameraDirection)}
            currentTab={currentCamera?.cameraDirection}
            onClickTab={onClickTab}
          />
          <div className={styles}>
            {currentCamera ? (
              <SingleImage camera={currentCamera} isMwos={false} />
            ) : (
              <AllImages
                cameras={cameras}
                isMwos={false}
                onClick={onClickImage}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

const styles = css({
  display: 'block',
  position: 'relative',
  overflow: 'hidden',
});
